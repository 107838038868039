import "./Style.css";
import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import { astroData } from "./TalkAstroData";
import TalkAstroFile from "./TalkAstroFile";
const TalkAstroSearch = () => {
  const [talkData, setTalkData] = useState(astroData);
  const navigate = useNavigate();
  return (
    <div className="container mt-4">
      <div className="talkastro">
        <div className="talkastro-content" >
          <h5>Talk to an Astrologer</h5>
          <a className="seeall" style={{ cursor: "pointer", fontWeight:"700", color:" #d73878"}} onClick={() => { navigate('/AstrologerCardSearch') }}>SEE ALL<i className="fa fa-chevron-right" style={{ fontSize: "14px", padding: "5px" }}></i></a>
        </div>

        <p id="talk-para">And get solution and Problems</p>

        <section className="item-section mt-4 container">
          <div className="row talkdata">
            <TalkAstroFile data={talkData} className="col-3 mb-2 change-row"/>
          </div>
        </section>
      </div>

    </div>
  );
};
export default TalkAstroSearch;
