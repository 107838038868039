

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const FamousAstrologer = () => {
  const [bannerImage, setBannerImage] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.post('https://app.astrovedaguru.com/api/getBanner');
     
      const bannerData = response.data.recordList.find((element, index) => index === 3); // Find image at index 3
      if (bannerData) {
        setBannerImage(`https://app.astrovedaguru.com/${bannerData.bannerImage}`);
      } else {
        console.error('Banner image at index 3 not found.');
      }
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };

  return (
    <>
      <div className='container mt-4'>
        {bannerImage && (
          <img
            src={bannerImage}
            className="banner"
            alt="Banner"
            onClick={() => navigate("/AstrologerCardSearch")}
          />
        )}
      </div>
    </>
  );
};

export default FamousAstrologer;
