import React, { useState } from 'react'
import { useNavigate } from "react-router-dom";
import { chatData } from "./ChatAstroData";
import ChatAstroFile from "./ChatAstroFile";
const ChatAstroSearch = () => {
  const [chatsData, setChatData] = useState(chatData);
  const navigate = useNavigate();
  return (
    <div className="container mt-4">
      <div className="chatdata">
        <div className="chat-content">
          <h5>Chat With Astrologer</h5>
          <a className="seeall" style={{ cursor: "pointer", fontWeight:"700", color:" #d73878" }} onClick={() => { navigate('/ChatAstroSearch1') }}>SEE ALL<i className="fa fa-chevron-right" style={{ fontSize: "14px", padding: "5px" }}></i></a>
        </div>
        <p>And get solutions to your Problems</p>
        <section className="item-section mt-4 container">
          <div className="row talkdata">
            <ChatAstroFile data={chatsData} className="col-3 mb-2" />
          </div>
        </section>
      </div>
    </div>

  );
};

export default ChatAstroSearch;