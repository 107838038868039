
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Modal, TextField, Button, Typography, Link } from "@mui/material";
import WalletIcon from '@mui/icons-material/Wallet';
import axios from "axios";
import './Style.css';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import Logo from "../images/avg-logo.svg";
import AcharyaPrem from "./AcharyaPrem";
import OrderHistory from "./OrderHistory";
import HoroscopeFile from "./HoroscopeFile";
import HoroscopeSearch from "./HoroscopeSearch";
import MonthlyFile from "./MonthlyFile";
import MonthlyFileSearch from "./MonthlyFileSearch";
import DensityMediumIcon from '@mui/icons-material/DensityMedium';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DoubleArrowSharpIcon from '@mui/icons-material/DoubleArrowSharp';
import HomeSharpIcon from '@mui/icons-material/HomeSharp';
import PhoneSharpIcon from '@mui/icons-material/PhoneSharp';
import MarkunreadIcon from '@mui/icons-material/Markunread';
import TempleHinduIcon from '@mui/icons-material/TempleHindu';
import SummarizeSharpIcon from '@mui/icons-material/SummarizeSharp';
import ArticleIcon from '@mui/icons-material/Article';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import ArrowCircleLeftRoundedIcon from '@mui/icons-material/ArrowCircleLeftRounded';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';

const Header = ({ }) => {
  const [login, setLogin] = useState(false);
  const [input1, setInput1] = useState("");
  const [input2, setInput2] = useState("");
  const [input3, setInput3] = useState("");
  const [input4, setInput4] = useState("");
  const [input5, setInput5] = useState("");
  const [input6, setInput6] = useState("");
  const [input7, setInput7] = useState("");
  const [show, setShow] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userName, setUserName] = useState(null);

  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    const name = localStorage.getItem("userName");
    if (token && name) {
      setIsAuthenticated(true);
      setUserName(name);
    }
  }, []);

  const showHide = () => {
    handleLoginInTo();
    setShow(true);
  };

  const showHide1 = () => {
    setShow(false);

  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post("https://app.astrovedaguru.com/api/user/add", {
        name: input1,
        contactNo: input2,
        email: input3,
        password: input4,
        birthDate: input5,
        birthTime: input6,
        birthPlace: input7,
      });

      console.log("Response from API:", response.data);

      setInput1("");
      setInput2("");
      setInput3("");
      setInput4("");
      setInput5("");
      setInput6("");
      setInput7("");
      alert("Signed up successfully");
      handleLoginInTo();
      showHide();
    } catch (error) {
      console.error("Error submitting data:", error);
    }
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post("https://app.astrovedaguru.com/api/login", {
        email: input3,
        password: input4,
      });

      console.log("Response from API:", response.data);

      localStorage.setItem("token", response.data.token);
      localStorage.setItem("userName", response.data.userName);
      // localStorage.setItem("totalWalletAmount", profileResponse.data.totalWalletAmount);
      // localStorage.setItem("userName", profileResponse.data.email);
      // localStorage.setItem("userName", profileResponse.data.birthDate);
      // localStorage.setItem("userName", profileResponse.data.birthTime);
      // localStorage.setItem("userName", profileResponse.data.birthPlace);


      const profileResponse = await axios.post("https://app.astrovedaguru.com/api/getProfile", {}, {
        headers: {
          Authorization: `Bearer ${response.data.token}`
        }
      });

      // console.log("User Profile:", profileResponse.data.data.totalWalletAmount);
      setIsAuthenticated(true);
      if (setIsAuthenticated) {
        localStorage.setItem("userName", profileResponse.data.data.name);
        localStorage.setItem("contactNo", profileResponse.data.data.contactNo);
        localStorage.setItem("email", profileResponse.data.data.email);
        localStorage.setItem("birthDate", profileResponse.data.data.birthDate);
        localStorage.setItem("birthTime", profileResponse.data.data.birthTime);
        localStorage.setItem("birthPlace", profileResponse.data.data.birthPlace);
        localStorage.setItem("totalWalletAmount", profileResponse.data.data.totalWalletAmount);
        localStorage.setItem("location", profileResponse.data.data.location);
        localStorage.setItem("pincode", profileResponse.data.data.pincode);
        localStorage.setItem("countryCode", profileResponse.data.data.countryCode);

      }
      setInput3("");
      setInput4("");
      showHide1();

      setShow(false);

    } catch (error) {
      console.error("Error submitting data:", error);
      alert("Password is incorrect Please try again.")
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userName");
    localStorage.removeItem("email");
    localStorage.removeItem("firstName")
    localStorage.removeItem("lastName")
    localStorage.removeItem("birthDate");
    localStorage.removeItem("birthTime");
    localStorage.removeItem("totalWalletAmount");
    localStorage.removeItem("contactNo");
    localStorage.removeItem("addressLine1")
    localStorage.removeItem("gender")
    localStorage.removeItem("language")

    setIsAuthenticated(false);
    setUserName(null);
    navigate('/');
  };

  const handleLoginIn = () => {
    setLogin(true);
    showHide1();
  };

  const handleLoginInTo = () => {
    setLogin(false);
    setShow(false);
  };

  const handleContactNoChange = (e) => {
    const value = e.target.value;
    if (/^(\+91)?\d{0,10}$/.test(value)) {
      setInput2(value);
    }
  };

  const token = localStorage.getItem("token");
  const functionLogin = () => {
    if (!token) {
      showHide();
    } else {
      handleLoginIn();
      handleLoginInTo();
    }
  }

  const alt = () => {
    alert("Hey")
  } // drawer

 

  const [openDrawer, setOpenDrawer] = useState(false);

  const DrawerList = (
    <Box sx={{ width: 222, height: '70%'}} role="presentation" onClick={() => setOpenDrawer(false)}>
      <div className="sidebar" style={{ display: "flex", alignItems: "center", }}>
        <img src={Logo} style={{ width: "70px", height: "auto", marginLeft: "15px" }} />
        <Typography variant="h6" sx={{ fontWeight: "bold", color: "#d73878", justifyContent: "center", alignItems: "center", letterSpacing: "-1.5px" }}>Astro Veda Guru</Typography>
      </div>
      <hr></hr>
      <Typography variant="h6" sx={{ marginLeft: "20px", marginTop: "-7px", color: "#d73878", fontWeight: "bold" }}>Hello, {localStorage.getItem("userName")}</Typography>
      {/* <Typography onClick={functionLogin} variant="h6" sx={{ fontWeight: "bold", marginLeft: "20px", marginTop: "-1px", color: "#d73878", cursor: "pointer" }}><DoubleArrowSharpIcon sx={{ fontSize: "20px", marginLeft: "5px", fontWeight: "bold", justifyContent: "center", alignItems: "center", }} />{localStorage.getItem("userName")}</Typography> */}
      <hr></hr>
      <List sx={{ marginTop: "-20px" }}>
        <ListItem button key="Home" onClick={() => navigate('/')}>
          <ListItemIcon><HomeSharpIcon sx={{ color: "#d73878", fontSize: "25px" }} /></ListItemIcon>
          <ListItemText primary="Home" style={{ color: "#d73878", marginLeft: "-10px" }} />
        </ListItem>
        <ListItem button key="Talk To Astrologer" onClick={() => navigate('/AstrologerCardSearch')}>
          <ListItemIcon><PhoneSharpIcon sx={{ color: "#d73878", fontSize: "25px" }} /></ListItemIcon>
          <ListItemText primary="Talk to Astrologer" style={{ marginLeft: "-10px", color: "#d73878" }} />
        </ListItem>
        <ListItem button key="Chat With Astrologer" onClick={() => navigate('/ChatAstroSearch1')}>
          <ListItemIcon><MarkunreadIcon sx={{ color: '#d73878', fontSize: "25px" }} /></ListItemIcon>
          <ListItemText primary="Chat with Astrologer" style={{ marginLeft: "-10px", color: "#d73878" }} />
        </ListItem>
        <ListItem button key="Puja Consultation" onClick={() => navigate('/Puja-Seva')}>
          <ListItemIcon><TempleHinduIcon sx={{ color: "#d73878", fontSize: "25px" }} /></ListItemIcon>
          <ListItemText primary="Puja Consultation" style={{ marginLeft: "-10px", color: "#d73878" }} />
        </ListItem>
        <ListItem button key="Reports" onClick={() => navigate('/Reports')}>
          <ListItemIcon><SummarizeSharpIcon sx={{ color: "#d73878", fontSize: "25px" }} /></ListItemIcon>
          <ListItemText primary="Reports" style={{ marginLeft: "-10px", color: "#d73878" }} />
        </ListItem>
        <ListItem button key="Articles">
          <ListItemIcon><ArticleIcon sx={{ color: "#d73878", fontSize: "25px" }} /></ListItemIcon>
          <ListItemText primary="Articles" style={{ marginLeft: "-10px", color: "#d73878" }} />
        </ListItem>
      </List>

    </Box>
  );


  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const closeLogout = () => {
    localStorage.removeItem("userName");
    localStorage.removeItem("token");
    handleCloseModal();
    setIsAuthenticated(false);
    navigate("/");
  }

  









  return (
    <>

      <Drawer open={openDrawer} onClose={() => setOpenDrawer(false)} sx={{ '& .MuiDrawer-paper': { width: 250 } }}>
        {DrawerList}

      </Drawer>
      <TemporaryDrawer />
      <div className="header-container">
        <div className="App-header">
          <DensityMediumIcon sx={{marginLeft:"10px", cursor:"pointer",'&:hover': { color: '#d73878' },}} onClick={() => setOpenDrawer(true)} />
          <img src={Logo} style={{ borderRadius: "5%", cursor:"pointer"}} className="App-logo" alt="logo" onClick={() => (navigate("/"))} />
        </div>
        <div className="project">
          <Typography className="proheading" variant="h4" fontWeight={700} sx={{ marginTop: "7px", fontFamily: "sen-serif", color: "#d73878", alignContent: "center", justifyContent: "center", alignItems: "center" }}>Astro Veda Guru</Typography>
        </div>
        <div className="login-form">

          {isAuthenticated && (
            <>
              <div className="wallet-logo">
                <WalletIcon sx={{ fontSize: "30px", cursor:"pointer" }} onClick={() => { navigate('/Wallet') }} />
              </div>
            </>
          )}

          {isAuthenticated ? (
            <>
              <Typography className="welcome" variant="body1" sx={{ marginRight: 2, cursor: "pointer"}} onClick={handleOpenModal}>
               <AccountCircleOutlinedIcon sx={{fontSize:"30px", marginRight:"-15px"}}/>
              </Typography>

              <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
                className="click-user"
              >
                <Box
                  sx={{
                    position: 'absolute',
                    top: '25%',
                    left: '80%',
                    transform: 'translate(-50%, -50%)',
                    width: 250,
                    bgcolor: 'background.paper',
                    // boxShadow: 20,
                    p: 4,
                    borderRadius: 2,
                    outline: 'none',
                    hover: 'gray'

                  }}
                >
                  <Typography id="modal-title" variant="h6" component="h2" gutterBottom sx={{ marginTop: "-25px", fontWeight: "bold", fontSize: "20px" }}>
                    {localStorage.getItem("userName")}
                  </Typography>
                  <hr></hr>
                  <div style={{ display: "flex", justifyContent: "space-between", cursor: "pointer", '&:hover': { color: 'Blue' }, }} onClick={() => { (navigate("/UserProfile")); handleCloseModal() }}>
                    <div>
                      <Typography id="modal-description" variant="body1" fontSize="15px"gutterBottom sx={{ '&:hover': { color: 'Blue' }, }}>
                        <AccountBoxIcon sx={{ marginRight: "20px", fontSize: "25px" }} />
                        My Profile
                      </Typography>
                    </div>
                    <div>
                      <Typography variant="body1" fontSize="15px" gutterBottom sx={{ color: "#d73878", fontWeight: "bold" }}>Edit</Typography>
                    </div>
                  </div>
                  <div style={{ display: "flex", justifyContent: "space-between", cursor: "pointer" }} onClick={() => { (navigate("/Wallet")); handleCloseModal() }}>
                    <div>
                      <Typography id="modal-description" variant="body1" gutterBottom fontSize="15px" sx={{ cursor: "pointer", '&:hover': { color: 'Blue' }, }}>
                        <WalletIcon sx={{ marginRight: "20px", fontSize: "25px" }} />
                        My Wallet
                      </Typography>
                    </div>
                    <div>
                      <Typography variant="body1" fontSize="15px" sx={{color: "#d73878", fontWeight:"bold", marginTop:"3px", marginRight:"-20px"}}gutterBottom><CurrencyRupeeIcon  sx={{ fontSize: "16px", marginTop: "-2px",color: "#d73878" }} />{localStorage.getItem('totalWalletAmount')}</Typography>
                    </div>
                  </div>
                  <Typography id="modal-description" variant="body1" fontSize="15px" gutterBottom onClick={() => { (navigate("/OrderHistory")); handleCloseModal() }} sx={{ cursor: "pointer", '&:hover': { color: 'Blue' }, }}>
                    <WalletIcon sx={{ marginRight: "20px", fontSize: "25px" }} />
                    Order history
                  </Typography>
                  {/* <Typography id="modal-description" variant="body1" gutterBottom onClick={() => { (navigate("/PendingOrder")); handleCloseModal() }} sx={{ cursor: "pointer", '&:hover': { color: 'Blue' }, }}>
                    <WalletIcon sx={{ marginRight: "20px", fontSize: "30px" }} />
                    Pending Orders
                  </Typography> */}
                  <Typography id="modal-description" variant="body1" fontSize="15px" gutterBottom onClick={() => { (navigate("/ContactDetail")); handleCloseModal() }} sx={{ cursor: "pointer", '&:hover': { color: 'Blue' }, }}>
                    <EmailOutlinedIcon sx={{ marginRight: "20px", fontSize: "25px" }} />
                    Customer Support
                  </Typography>
                  <Typography id="modal-description" variant="body1" gutterBottom sx={{ cursor: "pointer",'&:hover': { color: 'Blue' }, }} onClick={closeLogout}>
                    <ArrowCircleLeftRoundedIcon sx={{ marginRight: "20px", fontSize: "px" }} />
                    Logout
                  </Typography>
                  {/* <Button onClick={handleCloseModal} variant="contained" color="primary" sx={{ marginTop: "10px" }}>
                    Close Modal
                  </Button> */}
                </Box>
              </Modal>



              <Button className="loginbutton-log"
                sx={{ borderRadius: 2 }}
                variant='contained'
                color='secondary'
                alignitems="center"
                justifycontent={"center"}
                onClick={handleLogout}
              >
                Logout
              </Button>
            </>
          ) : (
            <Button className="loginbutton"
              onClick={showHide}
              sx={{ borderRadius: 2 }}
              variant='contained'
              color="primary"
              alignitems="center"
              justifycontent={"center"}
            ><AccountCircleOutlinedIcon style={{fontSize:"25px", marginRight:"8px"}}/>
              Login
            </Button>
          )}
          <Modal className="signup-modal"
            open={login}

          // onClose={handleLoginInTo}
          >
            <Box className="box"
              sx={{
                backgroundColor: 'white',
                border: '1px solid #1E74B3',
                borderRadius: '13px',
                padding: '4.5rem',
                outline: 'none',
                height: 'auto',
              }}
            >
              <div class="modal-header cross-close-btn" style={{ display: "flex", justifyContent: "flex-end" }}>
                <button style={{ fontSize: "20px" }} type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleLoginInTo}></button>
              </div>

              <form className="signup-form">
                <Typography className="signup-heading" variant="h4" sx={{ fontWeight: "bold", textDecoration: "underline" }}>Signup Form</Typography>
                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                    <div className="card">
                      <TextField className="name-field" onChange={(e) => setInput1(e.target.value)} value={input1} name="name" margin="normal" type={'text'} variant='outlined' placeholder='Name' size="small" required />
                    </div>
                  </div>

                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                    <div className="card">
                      <TextField className="contact-field" onChange={handleContactNoChange} value={input2} name="contactNo" margin="normal" type={'text'} variant='outlined' placeholder='Contact No' size="small" required />
                    </div>
                  </div>

                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                    <div className="card">
                      <TextField className="email-field" onChange={(e) => setInput3(e.target.value)} value={input3} name="email" margin="normal" type={'email'} variant='outlined' placeholder='Email' size="small" required />
                    </div>
                  </div>

                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                    <div className="card">
                      <TextField className="password-field" error={input4 && !/(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.{8,})/.test(input4)}
                        helperText={
                          input4 &&
                            !/(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.{4,})/.test(input4)
                            ? "Password at least 4 characters, 1 capital letter, and 1 symbol (!@#$%^&*)"
                            : ""
                        } onChange={(e) => setInput4(e.target.value)} value={input4} name="password" margin="normal" type={showPassword ? 'text' : 'password'} variant='outlined' placeholder='Password' size="small" required InputProps={{
                          endAdornment: (
                            <VisibilityIcon
                              onClick={togglePasswordVisibility}
                              style={{ cursor: "pointer" }}
                            />
                          )
                        }} />
                    </div>
                  </div>

                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                    <div className="card">
                      <input type="date" value={input5} onChange={(e) => setInput5(e.target.value)} className="date-field form-control"></input>
                    </div>
                  </div>

                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                    <div className="card">
                      <input type="time" value={input6} onChange={(e) => setInput6(e.target.value)} className="time-field form-control"></input>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="card">
                      <TextField className="birth-field" onChange={(e) => setInput7(e.target.value)} value={input7} name="birthPlace" margin="normal" type={'text'} variant='outlined' placeholder='Birth Place' size="small" required />
                    </div>
                  </div>

                  <div className="col-12 mt-3">
                    <div className="card">
                      <Button className="signup-field" onClick={handleSubmit} variant='contained' size="large" color='secondary'>Signup</Button>
                    </div>
                  </div>
                </div>
              </form>
            </Box>
          </Modal>
        </div>
      </div>

      {show && (
        <Modal className="login-modal"
          open={show}
        // onClose={showHide1}
        >
          <Box className="box-1"
            sx={{

              backgroundColor: 'white',
              border: '1px solid #1E74B3',
              borderRadius: '13px',
              padding: '5rem',
              outline: 'none',
              height: 'auto'
            }}
          >
            <div className="modal-header cross-login-btn" style={{ display: "flex", justifyContent: "flex-end" }}>
              <button style={{ fontSize: "20px" }} type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={showHide1}></button>
            </div>

            <form className="login-for">

              <Typography className="login-heading" variant="h4" sx={{ fontWeight: "bold", fontSize: "22px", textDecoration: "underline" }}>Login Form</Typography>
              <TextField className="login-email-field" onChange={(e) => setInput3(e.target.value)} value={input3} name="email" margin="normal" type={'email'} variant='outlined' placeholder='Email' size="small" />
              <TextField className="login-password-field" error={input4 && !/(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.{8,})/.test(input4)}
                helperText={
                  input4 &&
                    !/(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.{4,})/.test(input4)
                    ? "Password at least 4 characters, 1 capital letter, and 1 symbol (!@#$%^&*)"
                    : ""
                } onChange={(e) => setInput4(e.target.value)} value={input4} name="password" margin="normal" type={showPassword ? 'text' : 'password'} variant='outlined' placeholder='Password' size="small" InputProps={{
                  endAdornment: (
                    <VisibilityIcon
                      onClick={togglePasswordVisibility}
                      style={{ cursor: "pointer", marginTop: "1px", marginLeft: "-20px" }}
                    />
                  )
                }} />
              <Typography onClick={() => { showHide1(); navigate("/ForgotPassword") }} className="forgot" style={{ cursor: "pointer", marginTop: "10px", }}><Link>Forgot Password</Link></Typography>
              <Typography className="have-account" variant="h6" onClick={handleLoginIn} style={{ cursor: "pointer", marginTop: "10px" }} ><Link> Don't have an account</Link></Typography>

              <Button className="login-field" variant='contained' color='secondary' onClick={handleLogin}>Log In</Button>
            </form>
          </Box>
        </Modal >
      )}
      <AcharyaPrem showHide={showHide} />
      <MonthlyFileSearch functionLogin={functionLogin} />
      {/* <HoroscopeSearch functionLogin={functionLogin}/> */}
    </>
  );
};

export default Header;

function TemporaryDrawer() {
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  return (
    <div>
      <Drawer open={open} onClose={toggleDrawer(false)}>
      </Drawer>
    </div>
  );
}







