import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Typography } from "@mui/material";
import ArrowBackSharpIcon from '@mui/icons-material/ArrowBackSharp';
import axios from "axios";
const FamousCard = () => {

    const navigate = useNavigate()
    const [bannerImage, setBannerImage] = useState('');
    
    const [products, setProducts] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.post('https://app.astrovedaguru.com/api/getBanner');
                const bannerData = response.data.recordList.find((element, index) => index === 0);
                if (bannerData) {
                    setBannerImage(`https://app.astrovedaguru.com/${bannerData.bannerImage}`);
                } else {
                    console.error('Banner image at index 1 not found.');
                }

                const productsResponse = await axios.post('https://app.astrovedaguru.com/api/getAstromallProduct');
                console.log('API Response:', productsResponse.data);

                if (productsResponse.status === 200) { 
                    const filterIds = [26, 27, 28, 29];
                    const filteredProducts = productsResponse.data.recordList.filter(product =>
                        filterIds.includes(product.id)
                    );
                    setProducts(filteredProducts);
                } else {
                    console.error('API request failed with status:', productsResponse.status);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    const handleCardClick = (product) => {
        // Navigate to BrahaspatiGraha component with product data
        navigate(`/brahaspati-graha`, { state: { astrologerCard: product } });
    }


    return (
        <>
            <div className='main-panchang'>
                <div className='acharya-ajay'>
                    <Typography variant='h6' fontWeight="700" sx={{ alignitems: "center", padding: "10px" }}><ArrowBackSharpIcon fontWeight="bold" sx={{ fontSize: "20px", marginRight: "10px", alignitems: "center" }} onClick={() => { navigate("/") }} />Online Puja</Typography>
                </div>
                <div className='container mt-4'>
                    {/* <img src={Banner3} /> */}
                    {bannerImage && <img src={bannerImage} alt="Banner" style={{ width: '100%' }} />}
                </div>
                <div className='container mt-4'>
                    <Button sx={{ borderRadius: 6, background: "black" }} variant='contained' color='warning' alignitems="center" justifycontent={"center"}>Personalized puja</Button>
                    <Typography className="personal" variant='h4'>Personalized puja</Typography>
                    <Typography variant='p' fontWeight="500" color="#696c70">Participate in a personalized puja crafted to align with your individual needs with just a click away.</Typography>
                </div>
                <>

                    <div className='container mt-4'>
                        <div className='row'>
                            {products.map((product, index) => (
                                <div key={product.id} className='col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-1'>
                                    <div className='card graha-shanti' onClick={() => handleCardClick(product)}>
                                        <img src={`https://app.astrovedaguru.com/${product.productImage}`} alt="loading" />
                                        <Typography variant='h3' sx={{ marginTop: "15px", fontWeight: "bold" }}>{product.name}</Typography>
                                        <Typography variant='h5' sx={{ fontSize: "16px", color: "rgb(129, 129, 129)" }}>{product.features}</Typography>
                                        <Button className="participate-now" sx={{ borderRadius: 1 }} variant='contained' color='warning'>Participate Now</Button>
                                    </div>
                                </div>
                            ))}
                            </div>
                            {/* {products.map((product, index) => (
                                <div key={product.id} className='col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-1'>
                                    <div className='card graha-shanti' onClick={() => (navigate("/Mohini-Puja"))}>
                                        <img src={`https://app.astrovedaguru.com/${product.productImage}`} />
                                        <Typography variant='h3' sx={{ marginTop: "15px", fontWeight: "bold" }}>{product.name}</Typography>
                                        <Typography variant='h5' sx={{ fontSize: "16px", color: "rgb(129, 129, 129)" }}>{product.features}</Typography>
                                        <Button className="participate-now" sx={{ borderRadius: 1 }} variant='contained' color='warning' alignitems="center" justifycontent={"center"}>Participate Now</Button>
                                    </div>
                                </div>
                            ))} */}
                        
                        {/* <div className='row'>
                            {products.map((product, index) => (
                                <div key={product.id} className='col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-1'>
                                    <div className='card graha-shanti' onClick={() => (navigate("/Vishkanthak-Dosh"))}>
                                        <img src={`https://app.astrovedaguru.com/${product.productImage}`} />
                                        <Typography variant='h3' sx={{ marginTop: "15px", fontWeight: "bold" }}>{product.name}</Typography>
                                        <Typography variant='h5' sx={{ fontSize: "16px", color: "rgb(129, 129, 129)" }}>{product.features}</Typography>
                                        <Button className="participate-now" sx={{ borderRadius: 1 }} variant='contained' color='warning' alignitems="center" justifycontent={"center"}>Participate Now</Button>
                                    </div>
                                </div>
                            ))}

                            <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-1'>
                                <div className='card graha-shanti' onClick={() => (navigate("/Asadha-Puja"))}>
                                    <img src={Banner7} />
                                    <Typography variant='h3' sx={{ marginTop: "15px", fontWeight: "bold" }}>Asadha Puja</Typography>
                                    <Typography variant='h5' sx={{ fontSize: "16px", color: "rgb(129, 129, 129)" }}>This month, there is a tradition of worshiping Mangaldev along with specially worshiping Lord Surya. Apart from this, the month of Ashadh is also considered as the month of worship and devotion to Lord Shiva and Lord Vishnu.</Typography>
                                    <Button className="participate-now" sx={{ borderRadius: 1 }} variant='contained' color='warning' alignitems="center" justifycontent={"center"}>Participate Now</Button>
                                </div>
                            </div>
                        </div> */}
                        {/* <div className='row'>
                        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-1'>
                            <div className='card graha-shanti' onClick={() => (navigate("/Gath-Bandhan-Puja"))}>
                                <img src={Banner8} />
                                <Typography variant='h3' sx={{ marginTop: "15px", fontWeight: "bold" }}>Gath Bandhan Puja</Typography>
                                <Typography variant='h5' sx={{ fontSize: "16px", color: "rgb(129, 129, 129)" }}>Gath Bandhan Puja signifies the matrimonial unity between Parvati and Shiva</Typography>
                                <Button className="participate-now" sx={{ borderRadius: 1 }} variant='contained' color='warning' alignitems="center" justifycontent={"center"}>Participate Now</Button>
                            </div>
                        </div>

                        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-1'>
                            <div className='card graha-shanti' onClick={() => (navigate("/Mangal-Graha-Shanti-Puja"))}>
                                <img src={Banner9} />
                                <Typography variant='h3' sx={{ marginTop: "15px", fontWeight: "bold" }}>Mangal Graha Shanti Puja</Typography>
                                <Typography variant='h5' sx={{ fontSize: "16px", color: "rgb(129, 129, 129)" }}>Mars is considered to be a very fast and powerful planet. This is the reason why its auspicious and inauspicious yoga in the horoscope determines the altitude of life. Mars is the planet of energy, courage, action, patience, self-confidence, desire, aggression and anger.</Typography>
                                <Button className="participate-now" sx={{ borderRadius: 1 }} variant='contained' color='warning' alignitems="center" justifycontent={"center"}>Participate Now</Button>
                            </div>
                        </div>
                        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-1'>
                            <div className='card graha-shanti' onClick={() => (navigate("/Chandra-Graha-Shanti-Puja"))}>
                                <img src={Banner10} />
                                <Typography variant='h3' sx={{ marginTop: "15px", fontWeight: "bold" }}>Chandra Graha Shanti Puja</Typography>
                                <Typography variant='h5' sx={{ fontSize: "16px", color: "rgb(129, 129, 129)" }}>Chandra is the driver of emotions and behavioural actions. The waxing and waning moons have a direct impact on our lives.</Typography>
                                <Button className="participate-now" sx={{ borderRadius: 1 }} variant='contained' color='warning' alignitems="center" justifycontent={"center"}>Participate Now</Button>
                            </div>
                        </div>
                        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-1'>
                            <div className='card graha-shanti' onClick={() => (navigate("/Shukra-Graha-Shanti-Puja"))}>
                                <img src={Banner11} />
                                <Typography variant='h3' sx={{ marginTop: "15px", fontWeight: "bold" }}>Shukra Graha Shanti Puja</Typography>
                                <Typography variant='h5' sx={{ fontSize: "16px", color: "rgb(129, 129, 129)" }}>Shukra (Venus) is the planet of love, beauty, creativity, romance, grace, comforts, joy, pleasure, marriage, sexuality, happiness prosperity, passion, emotional attachment, excitement, womanliness, wealth, good intellect, jewellery, wealth, glamour, attraction, sensitivity, artistic temperament, luxury and financial gain etc. An afflicted Venus can affect all these adversely. Shukra plays a pivotal role in people's horoscope.</Typography>
                                <Button className="participate-now" sx={{ borderRadius: 1 }} variant='contained' color='warning' alignitems="center" justifycontent={"center"}>Participate Now</Button>
                            </div>
                        </div>
                        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-1'>
                            <div className='card graha-shanti' onClick={() => (navigate("/Kalik-Kaalsarp-Dosh"))}>
                                <img src={Banner12} />
                                <Typography variant='h3' sx={{ marginTop: "15px", fontWeight: "bold" }}>Kalik kaalsarp dosh</Typography>
                                <Typography variant='h5' sx={{ fontSize: "16px", color: "rgb(129, 129, 129)" }}>Kalik Kaal Sarp Dosh Puja is a Hindu ritual performed to remove the negative effects of Kaal Sarp Dosh, a astrological condition that is believed to cause obstacles and challenges in one's life. The puja is typically performed at a temple or at home, and it involves a series of rituals, including prayers, offerings, and the chanting of mantras.</Typography>
                                <Button className="participate-now" sx={{ borderRadius: 1 }} variant='contained' color='warning' alignitems="center" justifycontent={"center"}>Participate Now</Button>
                            </div>
                        </div>
                        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-1'>
                            <div className='card graha-shanti' onClick={() => (navigate("/Navgraha-Shanti-Puja"))}>
                                <img src={Banner13} />
                                <Typography variant='h3' sx={{ marginTop: "15px", fontWeight: "bold" }}>Navgraha Shanti Puja</Typography>
                                <Typography variant='h5' sx={{ fontSize: "16px", color: "rgb(129, 129, 129)" }}>Navgraha Shanti Puja is a Hindu prayer or ritual performed to appease the nine planets (Navagrahas). It is believed that by performing this puja, one can gain the blessings of the planets and improve their luck, health, and overall well-being.</Typography>
                                <Button className="participate-now" sx={{ borderRadius: 1 }} variant='contained' color='warning' alignitems="center" justifycontent={"center"}>Participate Now</Button>
                            </div>
                        </div>
                        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-1'>
                            <div className='card graha-shanti' onClick={() => (navigate("/Parivar-Shanti"))}>
                                <img src={Banner14} />
                                <Typography variant='h3' sx={{ marginTop: "15px", fontWeight: "bold" }}>Pariwar Shanti</Typography>
                                <Typography variant='h5' sx={{ fontSize: "16px", color: "rgb(129, 129, 129)" }}>Parivar Shanti Pooja is performed for prosperous life, removal of any doshas with respect to ancestors anger or neighbors envy.</Typography>
                                <Button className="participate-now" sx={{ borderRadius: 1 }} variant='contained' color='warning' alignitems="center" justifycontent={"center"}>Participate Now</Button>
                            </div>
                        </div>
                        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-1'>
                            <div className='card graha-shanti' onClick={() => (navigate("/Gun-Dosh-Puja"))}>
                                <img src={Banner15} />
                                <Typography variant='h3' sx={{ marginTop: "15px", fontWeight: "bold" }}>Gun Dosh Puja</Typography>
                                <Typography variant='h5' sx={{ fontSize: "16px", color: "rgb(129, 129, 129)" }}>Gun dosh nivaran puja is a Hindu astrological practice that is performed to remove the negative effects of gun dosh, a type of astrological defect that is believed to cause obstacles and challenges in one's life. Gun dosh is caused by a mismatch between the gunas, or qualities, of the individual and their partner. The gunas are sattva (purity), rajas (activity), and tamas (inertia).</Typography>
                                <Button className="participate-now" sx={{ borderRadius: 1 }} variant='contained' color='warning' alignitems="center" justifycontent={"center"}>Participate Now</Button>
                            </div>
                        </div>
                        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-1'>
                            <div className='card graha-shanti' onClick={() => (navigate("/Navgraha-Shanti-Puja"))}>
                                <img src={Banner16} />
                                <Typography variant='h3' sx={{ marginTop: "15px", fontWeight: "bold" }}>Navgraha Shanti Puja</Typography>
                                <Typography variant='h5' sx={{ fontSize: "16px", color: "rgb(129, 129, 129)" }}>Navgraha Shanti Puja is a Hindu prayer or ritual performed to appease the nine planets (Navagrahas). It is believed that by performing this puja, one can gain the blessings of the planets and improve their luck, health, and overall well-being.</Typography>
                                <Button className="participate-now" sx={{ borderRadius: 1 }} variant='contained' color='warning' alignitems="center" justifycontent={"center"}>Participate Now</Button>
                            </div>
                        </div>
                        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-1'>
                            <div className='card graha-shanti' onClick={() => (navigate("/Surya-Graha-Shanti-Puja"))}>
                                <img src={Banner17} />
                                <Typography variant='h3' sx={{ marginTop: "15px", fontWeight: "bold" }}>Surya Graha Shanti Puja</Typography>
                                <Typography variant='h5' sx={{ fontSize: "16px", color: "rgb(129, 129, 129)" }}>The lord Surya (Sun) signifies Vitality, power, confidence, Soul’s energy, consistency, self-growth and development Personality, general health, Father, Rank, Authority, High position, Political career, Government Job etc.</Typography>
                                <Button className="participate-now" sx={{ borderRadius: 1 }} variant='contained' color='warning' alignitems="center" justifycontent={"center"}>Participate Now</Button>
                            </div>
                        </div>
                        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-1'>
                            <div className='card graha-shanti' onClick={() => (navigate("/NameAndFame"))}>
                                <img src={Banner18} />
                                <Typography variant='h3' sx={{ marginTop: "15px", fontWeight: "bold" }}>Name And Fame Consultation</Typography>
                                <Typography variant='h5' sx={{ fontSize: "16px", color: "rgb(129, 129, 129)" }}>Palmistry is a way of seeing a person's fate and personality by reading palm lines, hand shapes and colors, etc.</Typography>
                                <Button className="participate-now" sx={{ borderRadius: 1 }} variant='contained' color='warning' alignitems="center" justifycontent={"center"}>Participate Now</Button>
                            </div>
                        </div>
                        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-1'>
                            <div className='card graha-shanti' onClick={() => (navigate("/NameAndFame"))}>
                                <img src={Banner19} />
                                <Typography variant='h3' sx={{ marginTop: "15px", fontWeight: "bold" }}>Name And Fame Consultation</Typography>
                                <Typography variant='h5' sx={{ fontSize: "16px", color: "rgb(129, 129, 129)" }}>Palmistry is a way of seeing a person's fate and personality by reading palm lines, hand shapes and colors, etc.</Typography>
                                <Button className="participate-now" sx={{ borderRadius: 1 }} variant='contained' color='warning' alignitems="center" justifycontent={"center"}>Participate Now</Button>
                            </div>
                        </div>
                        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-1'>
                            <div className='card graha-shanti' onClick={() => (navigate("/SpellCasting"))}>
                                <img src={Banner20} />
                                <Typography variant='h3' sx={{ marginTop: "15px", fontWeight: "bold" }}>Spell Casting</Typography>
                                <Typography variant='h5' sx={{ fontSize: "16px", color: "rgb(129, 129, 129)" }}>Love Spell Career Spell Marriage Spell Relationship Spell Money Spell Prosperity Spell</Typography>
                                <Button className="participate-now" sx={{ borderRadius: 1 }} variant='contained' color='warning' alignitems="center" justifycontent={"center"}>Participate Now</Button>
                            </div>
                        </div>
                        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-1'>
                            <div className='card graha-shanti' onClick={() => (navigate("/Shukra-Graha-Shanti-Puja"))}>
                                <img src={Banner21} />
                                <Typography variant='h3' sx={{ marginTop: "15px", fontWeight: "bold" }}>Shukra Graha Shanti Puja</Typography>
                                <Typography variant='h5' sx={{ fontSize: "16px", color: "rgb(129, 129, 129)" }}>Shukra (Venus) is the planet of love, beauty, creativity, romance, grace, comforts, joy, pleasure, marriage, sexuality, happiness prosperity, passion, emotional attachment, excitement, womanliness, wealth, good intellect, jewellery, wealth, glamour, attraction, sensitivity, artistic temperament, luxury and financial gain etc. An afflicted Venus can affect all these adversely. Shukra plays a pivotal role in people's horoscope.</Typography>
                                <Button className="participate-now" sx={{ borderRadius: 1 }} variant='contained' color='warning' alignitems="center" justifycontent={"center"}>Participate Now</Button>
                            </div>
                        </div>
                        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-1'>
                            <div className='card graha-shanti' onClick={() => (navigate("/KetuGraha"))}>
                                <img src={Banner22} />
                                <Typography variant='h3' sx={{ marginTop: "15px", fontWeight: "bold" }}>Ketu Graha Shanti Puja</Typography>
                                <Typography variant='h5' sx={{ fontSize: "16px", color: "rgb(129, 129, 129)" }}>Ketu graha is also known as a Shadow Planet. Ketu is the remaining body part of the Asura Rahu. It is believed that, when Ketu Graha is wrongly placed, natives can suffer from theft, fatal accidents, marital life, putra dosha, absentmindedness and loss of property. It can make the lives of the natives very difficult.</Typography>
                                <Button className="participate-now" sx={{ borderRadius: 1 }} variant='contained' color='warning' alignitems="center" justifycontent={"center"}>Participate Now</Button>
                            </div>
                        </div>
                        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-1'>
                            <div className='card graha-shanti' onClick={() => (navigate("/SadeSati"))}>
                                <img src={Banner23} />
                                <Typography variant='h3' sx={{ marginTop: "15px", fontWeight: "bold" }}>Sade Sati Shani Puja</Typography>
                                <Typography variant='h5' sx={{ fontSize: "16px", color: "rgb(129, 129, 129)" }}>Shani Sade Sati, as the name suggests, it is 7.5 years transit of Lord Shani. If Saturn is ill placed in the birth chart then this Dosha is often associated with bad luck of 7.5 years which gets reflected in terms of finances, relationships, marital /problems, health issues etc.</Typography>
                                <Button className="participate-now" sx={{ borderRadius: 1 }} variant='contained' color='warning' alignitems="center" justifycontent={"center"}>Participate Now</Button>
                            </div>
                        </div>
                        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-1'>
                            <div className='card graha-shanti' onClick={() => (navigate("/BudhGraha"))}>
                                <img src={Banner24} />
                                <Typography variant='h3' sx={{ marginTop: "15px", fontWeight: "bold" }}>Budh Graha Shanti Puja</Typography>
                                <Typography variant='h5' sx={{ fontSize: "16px", color: "rgb(129, 129, 129)" }}>Budh signifies intelligence, retentive ability, cleverness, sharpness, trading ability, good writing ability.</Typography>
                                <Button className="participate-now" sx={{ borderRadius: 1 }} variant='contained' color='warning' alignitems="center" justifycontent={"center"}>Participate Now</Button>
                            </div>
                        </div>
                        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-1'>
                            <div className='card graha-shanti' onClick={() => (navigate("/Mangal-Graha-Shanti-Puja"))}>
                                <img src={Banner25} />
                                <Typography variant='h3' sx={{ marginTop: "15px", fontWeight: "bold" }}>Mangal Graha Shanti Puja</Typography>
                                <Typography variant='h5' sx={{ fontSize: "16px", color: "rgb(129, 129, 129)" }}>Mars is considered to be a very fast and powerful planet. This is the reason why its auspicious and inauspicious yoga in the horoscope determines the altitude of life. Mars is the planet of energy, courage, action, patience, self-confidence, desire, aggression and anger.</Typography>
                                <Button className="participate-now" sx={{ borderRadius: 1 }} variant='contained' color='warning' alignitems="center" justifycontent={"center"}>Participate Now</Button>
                            </div>
                        </div>
                        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-1'>
                            <div className='card graha-shanti' onClick={() => (navigate("/Dashmi"))}>
                                <img src={Banner26} />
                                <Typography variant='h3' sx={{ marginTop: "15px", fontWeight: "bold" }}>Dashami Shraddha</Typography>
                                <Typography variant='h5' sx={{ fontSize: "16px", color: "rgb(129, 129, 129)" }}>Dashami Shraddha could be performed for those souls who have departed on Dashami Tithi. Deceased may be died in any Paksha or Lunar Fortnights i.e. Shukla Paksha (Bright Fortnight) or Krishna Paksha (Dark Fortnight).</Typography>
                                <Button className="participate-now" sx={{ borderRadius: 1 }} variant='contained' color='warning' alignitems="center" justifycontent={"center"}>Participate Now</Button>
                            </div>
                        </div>
                        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-1'>
                            <div className='card graha-shanti' onClick={() => (navigate("/Relashionship"))}>
                                <img src={Banner27} />
                                <Typography variant='h3' sx={{ marginTop: "15px", fontWeight: "bold" }}>Relationship Healing</Typography>
                                <Typography variant='h5' sx={{ fontSize: "16px", color: "rgb(129, 129, 129)" }}>This way, if These mantras are taken from the entire Vidhi Vidhan , then there is complete success. This is not under everyone's control, it can only be done by those who are fully trained in it. It is used to Attract someone.</Typography>
                                <Button className="participate-now" sx={{ borderRadius: 1 }} variant='contained' color='warning' alignitems="center" justifycontent={"center"}>Participate Now</Button>
                            </div>
                        </div>
                        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-1'>
                            <div className='card graha-shanti'>
                                <img src={Banner28} />
                                <Typography variant='h3' sx={{ marginTop: "15px", fontWeight: "bold" }}>Mangal Graha Shanti Puja</Typography>
                                <Typography variant='h5' sx={{ fontSize: "16px", color: "rgb(129, 129, 129)" }}>Mars is considered to be a very fast and powerful planet. This is the reason why its auspicious and inauspicious yoga in the horoscope determines the altitude of life. Mars is the planet of energy, courage, action, patience, self-confidence, desire, aggression and anger.</Typography>
                                <Button className="participate-now" sx={{ borderRadius: 1 }} variant='contained' color='warning' alignitems="center" justifycontent={"center"}>Participate Now</Button>
                            </div>
                        </div>
                        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-1'>
                            <div className='card graha-shanti' onClick={() => (navigate("/SadeSati"))}>
                                <img src={Banner29} />
                                <Typography variant='h3' sx={{ marginTop: "15px", fontWeight: "bold" }}>Sade Sati Shani Puja</Typography>
                                <Typography variant='h5' sx={{ fontSize: "16px", color: "rgb(129, 129, 129)" }}>Shani Sade Sati, as the name suggests, it is 7.5 years transit of Lord Shani. If Saturn is ill placed in the birth chart then this Dosha is often associated with bad luck of 7.5 years which gets reflected in terms of finances, relationships, marital /problems, health issues etc.</Typography>
                                <Button className="participate-now" sx={{ borderRadius: 1 }} variant='contained' color='warning' alignitems="center" justifycontent={"center"}>Participate Now</Button>
                            </div>
                        </div>
                        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-1'>
                            <div className='card graha-shanti' onClick={() => (navigate("/RahuGraha"))}>
                                <img src={Banner30} />
                                <Typography variant='h3' sx={{ marginTop: "15px", fontWeight: "bold" }}>Rahu Graha Shanti Puja</Typography>
                                <Typography variant='h5' sx={{ fontSize: "16px", color: "rgb(129, 129, 129)" }}>Known as a shadow planet Rahu signifies losses, unwanted changes/transfers, confrontations, uncertainty, fear of future. Rahu if happens to be malefic, afflicted or debilitated in the chart it can be a source of concern</Typography>
                                <Button className="participate-now" sx={{ borderRadius: 1 }} variant='contained' color='warning' alignitems="center" justifycontent={"center"}>Participate Now</Button>
                            </div>
                        </div>
                        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-1'>
                            <div className='card graha-shanti' onClick={() => (navigate("/ShaniGraha"))}>
                                <img src={Banner31} />
                                <Typography variant='h3' sx={{ marginTop: "15px", fontWeight: "bold" }}>Shani Graha Shanti Puja</Typography>
                                <Typography variant='h5' sx={{ fontSize: "16px", color: "rgb(129, 129, 129)" }}>Shani executes the effects of Karma, that is the evils that you have been committed in this birth or the previous. Shani dev being the God of Justice ensures that the person performing negative and wrong karma learns a good lesson.</Typography>
                                <Button className="participate-now" sx={{ borderRadius: 1 }} variant='contained' color='warning' alignitems="center" justifycontent={"center"}>Participate Now</Button>
                            </div>
                        </div>
                        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-1'>
                            <div className='card graha-shanti' onClick={() => (navigate("/Ekadashi"))}>
                                <img src={Banner32} />
                                <Typography variant='h3' sx={{ marginTop: "15px", fontWeight: "bold" }}>Ekadashi Shraddha</Typography>
                                <Typography variant='h5' sx={{ fontSize: "16px", color: "rgb(129, 129, 129)" }}>Ekadashi Shraddha is done for those deceased family members who died on Ekadashi Tithi, including both Shukla and Krishna Paksha Ekadashi.</Typography>
                                <Button className="participate-now" sx={{ borderRadius: 1 }} variant='contained' color='warning' alignitems="center" justifycontent={"center"}>Participate Now</Button>
                            </div>
                        </div>
                        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-1'>
                            <div className='card graha-shanti' onClick={() => (navigate("/Tiryodashi"))}>
                                <img src={Banner34} />
                                <Typography variant='h3' sx={{ marginTop: "15px", fontWeight: "bold" }}>Trayodashi Shraddha</Typography>
                                <Typography variant='h5' sx={{ fontSize: "16px", color: "rgb(129, 129, 129)" }}>Trayodashi Shraddha is also known as Teras Shraddha. Trayodashi Shraddha Tithi is also suitable to perform Shraddha for dead children. This Shraddha Tithi is also known as Kakbali and Balabholani Teras in Gujarat.</Typography>
                                <Button className="participate-now" sx={{ borderRadius: 1 }} variant='contained' color='warning' alignitems="center" justifycontent={"center"}>Participate Now</Button>
                            </div>
                        </div>
                        </div> */}
                    </div>

                </>
            </div>
        </>
    );
};

export default FamousCard;