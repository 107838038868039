import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, Button, TextField } from '@mui/material';
import ArrowBackSharpIcon from '@mui/icons-material/ArrowBackSharp';
import axios from 'axios';

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!email) {
      setError('Please fill in this field.');
      return;
    }

    try {
      const response = await axios.post(
        'https://app.astrovedaguru.com/api/forget-password',
        { email }
      );

      console.log('API Response:', response.data);
      navigate('/ResetPassword');
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div className="main-panchang">
      <div className="acharya-ajay">
        <Typography variant="h6" fontWeight="700" sx={{ alignItems: "center", padding: "10px" }}>
          <ArrowBackSharpIcon fontWeight="bold" sx={{ fontSize: "20px", marginRight: "10px", alignItems: "center" }} onClick={() => navigate("/")} />
          Forgot Password
        </Typography>
      </div>
      <div className="privacy-container">
        <div className="privacy-heading">
          <Typography variant="h6" fontWeight="700">Forgot Password</Typography>
        </div>
        <div className="container mt-3">
          <div className="row col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
            <div className="col-xl-8 col-lg-8 col-md-10 col-sm-12 col-12">
              <div className="forgot-password">
                <div className="container mt-3">
                  <Typography variant="body1" fontWeight="500" sx={{ fontSize: "20px" }}>Enter Register Email</Typography>
                  <TextField
                    value={email}
                    onChange={(e) => { setEmail(e.target.value); setError(''); }}
                    className="form-control"
                    type="email"
                    placeholder="Enter Register Email"
                    style={{ borderRadius: "8px", marginTop: "10px" }}
                    required
                    fullWidth
                  />
                  {error && <Typography variant="body1" style={{ color: 'red' }}>{error}</Typography>}
                </div>
              </div>
              <div className="col-12 mt-3 for-submit">
                <Button onClick={handleSubmit} variant="contained" className="forgot-submit" fullWidth>
                  Submit
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
