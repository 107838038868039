import React from 'react'
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";
import ArrowBackSharpIcon from '@mui/icons-material/ArrowBackSharp';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
const ChoosePandit = () => {

    const navigate = useNavigate();
    return (
        <>
            <div className='main-contact'>
                <div className='acharya-ajay'>
                    <Typography variant='h6' fontWeight="700" sx={{ alignitems: "center", padding: "20px" }}><ArrowBackSharpIcon fontWeight="bold" sx={{ fontSize: "25px", marginRight: "10px", alignitems: "center" }} onClick={() => { navigate("/BrahaspatiGraha") }} />Choose Pandit</Typography>
                </div>
                <div className='container mt-3' >
                    <div className='row'>
                        <div className='col-6'>
                            <div className='card card20-content'>
                                <div className="row">
                                    <div className='col-3'>
                                        <img src={"https://static.astrovedaGuru.com/astro-images/agents/vivek124.jpg"} alt="..." />
                                    </div>
                                    <div className='col-1'></div>
                                    <div className='col-8'>
                                        <div className='card20-content-1'>
                                            <h5 className='vivek'>Acharya Vivek Shukla</h5>
                                            <p className='v-rate'><CurrencyRupeeIcon sx={{fontSize:"18px", marginTop:"-4px", marginRight:"3px"}}/>4100/-</p>
                                        </div>
                                        <p>Exp: 6 Years</p>
                                        <p>Hindi</p>
                                        <p>Vedic, Lal Kitab, Horary</p>
                                        <button type="button" className="btn btn-warning select"><CheckCircleOutlineIcon sx={{fontSize:"18px", marginRight:"10px"}}/>Select</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-6'>
                            <div className='card card20-content'>
                                <div className="row">
                                    <div className='col-3'>
                                        <img src={"https://static.astrovedaGuru.com/astro-images/agents/Astrosubham%20ajaymedium.jpg"} alt="..." />
                                    </div>
                                    <div className='col-1'></div>
                                    <div className='col-8'>
                                        <div className='card20-content-1'>
                                            <h5 className='vivek'>Acharya Shubham</h5>
                                            <p className='v-rate'><CurrencyRupeeIcon sx={{fontSize:"18px", marginTop:"-4px", marginRight:"3px"}}/>5100/-</p>
                                        </div>
                                        <p>Exp: 6 Years</p>
                                        <p>Hindi</p>
                                        <p>Vedic, Lal Kitab, Horary</p>
                                        <button type="button" className="btn btn-warning select"><CheckCircleOutlineIcon sx={{fontSize:"18px", marginRight:"10px"}}/>Select</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ChoosePandit