import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Typography } from '@mui/material';
import ArrowBackSharpIcon from '@mui/icons-material/ArrowBackSharp';
import axios from 'axios';

const HoroscopeDetails = () => {
    const location = useLocation();
    const navigate = useNavigate();
    
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const [message, setMessage] = useState('');
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        name: '',
        contactNo: '',
        gender: '',
        dob: '',
        tob: '',
        emailAddress: '',
        language: '',
        maritalStatus: '',
        birthPlace: '',
        totalWalletAmount: ''
    });
    const [walletAmount, setWalletAmount] = useState(() => {
        const storedAmount = localStorage.getItem('totalWalletAmount');
        return storedAmount ? Number(storedAmount) : 0;
    });
    const [errors, setErrors] = useState({});
    const astrologerDetails = location.state?.astrologerDetails;

    useEffect(() => {
        const storedData = {
            firstName: localStorage.getItem('firstName') || '',
            lastName: localStorage.getItem('lastName') || '',
            name: localStorage.getItem('userName') || '',
            contactNo: localStorage.getItem('contactNo') || '',
            gender: localStorage.getItem('gender') || '',
            dob: localStorage.getItem('birthDate') || '',
            tob: localStorage.getItem('birthTime') || '',
            emailAddress: localStorage.getItem('email') || '',
            language: localStorage.getItem('language') || '',
            maritalStatus: localStorage.getItem('maritalStatus') || '',
            birthPlace: localStorage.getItem('birthPlace') || '',
            totalWalletAmount: localStorage.getItem('totalWalletAmount') || ''
        };
        setFormData(storedData);
        setName(storedData.name);
        setEmail(storedData.emailAddress);
        setPhone(storedData.contactNo);
    }, []);

    const validatePhoneNumber = (number) => {
        return number.length === 10;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validatePhoneNumber(phone)) {
            setPhoneError('Phone number must be 10 digits');
            return;
        }

        setPhoneError('');
        setErrors({});
        const token = localStorage.getItem('token');
        const totalWalletAmount = (localStorage.getItem('totalWalletAmount'));

        // if (totalWalletAmount < Number(astrologerDetails.amount)) {
        //     alert('Insufficient wallet balance. Please add funds to your wallet.');
        //     navigate('/Wallet');
        //     return;
        // }
            if (!astrologerDetails.amount || isNaN(astrologerDetails.amount) || Number(astrologerDetails.amount) <= 0) {
                alert('Please enter a valid amount');
                return;
            }
            try {
                const orderData = {
                    productCategoryId: astrologerDetails.productCategoryId, 
                    productId: astrologerDetails.id,        
                    orderAddress: `${document.querySelector('input[placeholder="Enter Flate No"]').value}, ${document.querySelector('input[placeholder="Enter your locality"]').value}`,
                    flatNo: document.querySelector('input[placeholder="Enter Flate No"]').value,
                    landmark: document.querySelector('input[placeholder="Enter your locality"]').value, //'Hanuman Mandir', 
                    city: document.querySelector('input[placeholder="Enter city name"]').value,
                    state: document.querySelector('input[placeholder="Enter State name"]').value,
                    country: document.querySelector('input[placeholder="Enter Country Name"]').value,
                    pincode: document.querySelector('input[placeholder="Enter pincode"]').value,
                    payableAmount: astrologerDetails.amount,
                    orderType: 'horoscope product',
                    gstPercent: 18,
                    totalPayable: astrologerDetails.amount * 1.18, // Amount + GST
                    payamentMethod: 'netbanking', 
                    orderStatus: 'Pending',
                    receipt: 'order_rcptid_11',
                    amount: astrologerDetails.amount
                };
        
                try {
                    const response = await axios.post('https://app.astrovedaguru.com/api/userOrder/add', orderData, {
                        headers: {
                            'Authorization': `Bearer ${token}`, 
                            'Content-Type': 'application/json'
                        }
                    });
                    
                const { order_id, key_id, amount } = response.data.verify;
                const options = {
                    "key": key_id,
                    "amount": amount, 
                    "currency": "INR",
                    "name": "Astrovedaguru",
                    "description": "Purchase Description",
                    "image": "https://astrovedaguru.com/static/media/avg-logo.02bd90076148acc059e4f6c3736cd706.svg",
                    "handler": async function (response) {
                        try {

                            // Verify payment with backend
                            const paymentResponse = await axios.post(
                                'https://app.astrovedaguru.com/api/addpayment',
                                {
                                    amount: amount,
                                    paymentMode: 'Razorpay',
                                    paymentReference: response.razorpay_payment_id,
                                    paymentStatus: 'Success',
                                    signature: response.razorpay_signature,
                                    orderId: order_id
                                },
                                {
                                    headers: {
                                        Authorization: `Bearer ${localStorage.getItem("token")}`
                                    }
                                }
                            );
                      
                        if(paymentResponse.data.recordList.paymentStatus=='Success'){                          

                            // console.log(paymentResponse.data.recordList.id);
                            // const updatedWalletAmount = Number(totalWalletAmount) - Number(astrologerDetails.amount);
                            // localStorage.setItem("totalWalletAmount", updatedWalletAmount);
                            // setWalletAmount(updatedWalletAmount);
                             // console.log(paymentResponse.data.recordList);
                            // alert(paymentResponse.data.recordList.orderId);
                            // alert(paymentResponse.data.recordListid.id);
                            // alert(paymentResponse.data.recordList.paymentReference);   
                            alert("Order placed successfully");

                            const paymentResponse2 = await axios.post(
                                'https://app.astrovedaguru.com/api/updateUserOrder',
                                {
                                    orderid: paymentResponse.data.recordList.orderId,
                                    gorderid:paymentResponse.data.recordList.id,
                                    gpayid: paymentResponse.data.recordList.paymentReference,
                                   
                                },
                                {
                                    headers: {
                                        Authorization: `Bearer ${localStorage.getItem("token")}`
                                    }
                                    
                                }
                                
                            );
                            navigate('/OrderHistory');
                        } else {
                            alert(paymentResponse.data.message);
                        //    navigate('/OrderHistory');
                        }

                           
                          //  navigate('/OrderHistory');
                        } catch (error) {
                            console.error('Payment verification error', error);
                            alert('Payment verification failed');
                        }
                    },
                    "prefill": {
                        "name": "John Doe",
                        "email": "john.doe@example.com",
                        "contact": "9999999999"
                    },
                    "theme": {
                        "color": "#3399cc"
                    }
                };
    
                const paymentObject = new window.Razorpay(options);
                paymentObject.open();
            } catch (error) {
                console.error('Payment error', error);
                alert('Failed to initiate payment');
            }


        } catch (error) {
            if (error.response && error.response.data) {
              
                setErrors(error.response.data);
                console.error('Error placing order:', error.response.data);
            } else {
                console.error('Error placing order:', error);
            }
        }
    };

    return (
        <>
            <div className='main-panchang'>
                <div className='acharya-ajay'>
                    <Typography variant='h6' fontWeight="700" sx={{ alignitems: "center", padding: "10px" }}>
                        <ArrowBackSharpIcon fontWeight="bold" sx={{ fontSize: "20px", marginRight: "10px", alignitems: "center" }} onClick={() => (navigate("/Reports"))} />
                        {astrologerDetails.name}
                    </Typography>
                </div>
                <div className='container mt-3'>
                    <div className='row'>
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-5 col-12 mb-1">
                            <div className='card brahaspati'>
                                <img className='graha-image' src={`https://app.astrovedaguru.com/${astrologerDetails.productImage}`} alt={astrologerDetails.name} />
                                <Typography variant='h6' sx={{ marginTop: "15px", marginLeft: "18px", fontWeight: "700", fontSize: "18px" }}>{astrologerDetails.name}</Typography>
                                <p>{astrologerDetails.features}</p>
                                <p style={{ color: "#d73878", marginTop: "-20px", fontWeight: "bold" }}>
                                    <span style={{ color: "#d73878", marginRight: "5px" }}></span>₹ {astrologerDetails.amount}
                                </p>
                            </div>
                        </div>
                        <div className="col-xl-8 col-lg-8 col-md-8 col-sm-7 col-12 mb-1">
                            <div className="card puja-sevash-1">
                                <form onSubmit={handleSubmit}>
                                    <div className='row d-flex justify-content-center'>
                                        <div className='col-10 mb-4'>
                                            <Typography variant="h2" fontWeight="600" marginLeft="0px" sx={{ fontSize: "24px", marginTop: "20px" }}>
                                                For a FREE puja consultation, kindly fill out the form, and expect a call from us.
                                            </Typography>
                                        </div>
                                        <div className='col-5'>
                                            <Typography variant="p" marginLeft="0px" sx={{ marginTop: "30px" }}>Enter Name</Typography>
                                            <input value={name} onChange={(e) => setName(e.target.value)} className="form-control" type="text" placeholder="Enter your Name" required />
                                        </div>
                                        <div className='col-5'>
                                            <Typography variant="p" marginLeft="0px" sx={{ marginTop: "12px" }}>Phone Number</Typography>
                                            <input value={phone} onChange={(e) => setPhone(e.target.value)} className="form-control" type="tel" placeholder="Enter Phone Number" required />
                                            {phoneError && (
                                                <Typography variant="p" marginLeft="0px" sx={{ color: "red" }}>
                                                    {phoneError}
                                                </Typography>
                                            )}
                                        </div>
                                        <div className='col-10 mb-3'>
                                            <Typography variant="h2" fontWeight="600" marginLeft="0px" sx={{ fontSize: "24px", marginTop: "20px" }}>
                                                Enter Your Address
                                            </Typography>
                                        </div>
                                        <div className='col-5'>
                                            <Typography variant="p" marginLeft="0px" sx={{ marginTop: "30px" }}>Flate No</Typography>
                                            <input className="form-control" type="text" placeholder="Enter Flate No" required />
                                        </div>
                                        <div className='col-5'>
                                            <Typography variant="p" marginLeft="0px" sx={{ marginTop: "12px" }}>Locality</Typography>
                                            <input className="form-control" type="text" placeholder="Enter your locality" required />
                                        </div>
                                        <div className='col-5 mt-2'>
                                            <Typography variant="p" marginLeft="0px" sx={{ marginTop: "12px" }}>City</Typography>
                                            <input className="form-control" type="text" placeholder="Enter city name" required />
                                        </div>
                                        <div className='col-5 mt-2'>
                                            <Typography variant="p" marginLeft="0px" sx={{ marginTop: "12px" }}>Enter Pincode</Typography>
                                            <input className="form-control" type="number" placeholder="Enter pincode" required />
                                        </div>
                                        <div className='col-5 mt-2'>
                                            <Typography variant="p" marginLeft="0px" sx={{ marginTop: "12px" }}>State</Typography>
                                            <input className="form-control" type="text" placeholder="Enter State name" required />
                                        </div>
                                        <div className='col-5 mt-2'>
                                            <Typography variant="p" marginLeft="0px" sx={{ marginTop: "12px" }}>Country</Typography>
                                            <input className="form-control" type="text" placeholder="Enter Country Name" required />
                                        </div>
                                        <div className='col-10 mt-3'>
                                            <Typography variant="p" marginLeft="0px" sx={{ marginTop: "12px" }}>Enter Your Message</Typography>
                                            <textarea value={message} onChange={(e) => setMessage(e.target.value)} name="message" placeholder="Enter your message" rows="3" className='form-control' required />
                                        </div>
                                        <div className='col-10 mt-3 mb-3'>
                                            <button type="submit" disabled={phoneError || phone.length !== 10} className="consultation1 btn" sx={{ alignitems: "center", cursor: "pointer" }}>
                                                PROCEED TO PAY
                                            </button>
                                        </div>
                                    </div>
                                </form>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default HoroscopeDetails;





























































































































// import React, { useState, useEffect } from 'react'
// import { useNavigate, useLocation } from 'react-router-dom'
// import { Typography } from '@mui/material';
// import ArrowBackSharpIcon from '@mui/icons-material/ArrowBackSharp';

// import axios from "axios";
// const HoroscopeDetails = () => {
//     const location = useLocation();
//     const navigate = useNavigate();
//     const [name, setName] = useState("");
//     const [email, setEmail] = useState("");
//     const [phone, setPhone] = useState("");
//     const [phoneError, setPhoneError] = useState("");
    
//     const [message, setMessage] = useState('');

//     const [formData, setFormData] = useState({
//         firstName: '',
//         lastName: '',
//         name: '',
//         contactNo: '',
//         gender: '',
//         dob: '',
//         tob: '',
//         emailAddress: '',
//         language: '',
//         maritalStatus: '',
//         birthPlace: '',
//         totalWalletAmount:''
//     });

//     useEffect(() => {
//         const storedData = {
//             firstName: localStorage.getItem('firstName') || '',
//             lastName: localStorage.getItem('lastName') || '',
//             name: localStorage.getItem('userName') || '',
//             contactNo: localStorage.getItem('contactNo') || '',
//             gender: localStorage.getItem('gender') || '',
//             dob: localStorage.getItem('birthDate') || '',
//             tob: localStorage.getItem('birthTime') || '',
//             emailAddress: localStorage.getItem('email') || '',
//             language: localStorage.getItem('language') || '',
//             maritalStatus: localStorage.getItem('maritalStatus') || '',
//             birthPlace: localStorage.getItem('birthPlace') || '',
//             totalWalletAmount: localStorage.getItem('totalWalletAmount') || ''

//         };
//         setFormData(storedData);
//         setName(storedData.name);
//         setEmail(storedData.emailAddress);
//         setPhone(storedData.contactNo);
//     }, []);

//     const handlePhoneChange = (e) => {
//         const re = /^[0-9\b]+$/;
//         const phoneNumber = e.target.value;

//         if (phoneNumber.length === 0 || re.test(phoneNumber)) {
//             setPhone(phoneNumber);
//             if (phoneNumber.length !== 10) {
//                 setPhoneError("Enter a valid 10-digit number.");
//             } else {
//                 setPhoneError("");
//             }
//         }
//     };
//     const astrologerDetails = location.state?.astrologerDetails;
//     const handleConsultation = () => {
//         const subject = "Finance Spell";
//         const emailMessage = `${message}, Subject: ${subject}`;
//         const data = {
//             name,
//             email,
//             phone,
//             message: emailMessage,
//         };
//         axios.post('https://app.astrovedaguru.com/api/sendMail', data)
//             .then(response => {
//                 console.log('Email sent successfully:', response.data);
//             })
//             .catch(error => {
//                 console.error('Error sending email:', error);
//             });
//     };



//     return (
//         <>
//             <div className='main-panchang'>
//                 <div className='acharya-ajay'>
//                     <Typography variant='h6' fontWeight="700" sx={{ alignitems: "center", padding: "10px" }}><ArrowBackSharpIcon fontWeight="bold" sx={{ fontSize: "20px", marginRight: "10px", alignitems: "center" }} onClick={() => (navigate("/Reports"))} />{astrologerDetails.name}</Typography>
//                 </div>
//                 <div className='container mt-3'>
//                     <div className='row'>
//                         <div className="col-xl-4 col-lg-4 col-md-4 col-sm-5 col-12 mb-1">
//                             <div className='card brahaspati'>
//                                 <img className='graha-image' src={`https://app.astrovedaguru.com/${astrologerDetails.productImage}`} alt={astrologerDetails.name} />
//                                 <Typography variant='h6' sx={{ marginTop: "15px", marginLeft: "18px", fontWeight: "700", fontSize: "18px" }}>{astrologerDetails.name}</Typography>
//                                 <p>{astrologerDetails.features}</p>
//                                 <p style={{ color: "#d73878", marginTop: "-20px", fontWeight: "bold" }}><span style={{ color: "#d73878", marginRight: "5px" }}></span>₹ {astrologerDetails.amount}</p>
//                             </div>

//                         </div>
//                         <div className="col-xl-8 col-lg-8 col-md-8 col-sm-7 col-12 mb-1">
//                             <div className="card puja-sevash-1">

//                                 <div className='col-xl-12 col-lg-12 col-md-12 col-12 mb-1'>
//                                     <div className='row'>
//                                         <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-1'>

//                                             <div className='row d-flex justify-content-center'>
//                                                 <div className='col-10 mb-4'>
//                                                     <Typography variant="h2" fontWeight="600" marginLeft="0px" sx={{ fontSize: "24px", marginTop: "20px" }}>For a FREE puja consultation, kindly fill out the form, and expect a call from us.</Typography>
//                                                 </div>

//                                                 <div className='col-5'>
//                                                     <Typography variant="p" marginLeft="0px" sx={{ marginTop: "30px" }}>Enter Name</Typography>
//                                                     <input value={name}
//                                                         onChange={(e) => setName(e.target.value)} className=" form-control" type="text" placeholder="Enter your Name" required></input>
//                                                     <input value={email}
//                                                         onChange={(e) => setEmail(e.target.value)} className="form-control" type="hidden" placeholder="Enter your Email" required></input>
//                                                 </div>
//                                                 <div className='col-5'>
//                                                     <Typography variant="p" marginLeft="0px" sx={{ marginTop: "12px" }}>Phone Number</Typography>

//                                                     <input value={phone}
//                                                         onChange={handlePhoneChange} className=" form-control" type="tel" placeholder="Enter Phone Number" required></input>
//                                                     {phoneError && (
//                                                         <Typography variant="p" marginLeft="0px" sx={{ color: "red" }}>
//                                                             {phoneError}
//                                                         </Typography>
//                                                     )}
//                                                 </div>
//                                                 <div className='col-10 mb-3'>
//                                                     <Typography variant="h2" fontWeight="600" marginLeft="0px" sx={{ fontSize: "24px", marginTop: "20px" }}>Enter Your Address</Typography>
//                                                 </div>
//                                                 <div className='col-5'>
//                                                     <Typography variant="p" marginLeft="0px" sx={{ marginTop: "30px" }}>Flate No</Typography>
//                                                     <input
//                                                         className=" form-control" type="number" placeholder="Enter Flate No" required></input>
//                                                 </div>
//                                                 <div className='col-5'>
//                                                     <Typography variant="p" marginLeft="0px" sx={{ marginTop: "12px" }}>Locality</Typography>
//                                                     <input className=" form-control" type="text" placeholder="Enter your locality" required></input>
//                                                 </div>
//                                                 <div className='col-5 mt-2'>
//                                                     <Typography variant="p" marginLeft="0px" sx={{ marginTop: "12px" }}>City</Typography>
//                                                     <input className=" form-control" type="text" placeholder="Enter city name" required></input>
//                                                 </div>

//                                                 <div className='col-5 mt-2'>
//                                                     <Typography variant="p" marginLeft="0px" sx={{ marginTop: "12px" }}>Enter Pincode</Typography>
//                                                     <input className=" form-control" type="number" placeholder="Enter pincode" required></input>
//                                                 </div>
//                                                 <div className='col-5 mt-2'>
//                                                     <Typography variant="p" marginLeft="0px" sx={{ marginTop: "12px" }}>State</Typography>
//                                                     <input className="form-control" type="text" placeholder="Enter State name" required></input>
//                                                 </div>

//                                                 <div className='col-5 mt-2'>
//                                                     <Typography variant="p" marginLeft="0px" sx={{ marginTop: "12px" }}>Country</Typography>
//                                                     <input className="form-control" type="text" placeholder="Enter Country Name" required></input>
//                                                 </div>

//                                                 <div className='col-10 mt-3'>
//                                                     <Typography variant="p" marginLeft="0px" sx={{ marginTop: "12px" }}>Enter Your Message</Typography>
//                                                     <textarea value={message} onChange={(e) => setMessage(e.target.value)} name="message" placeholder="Enter your message" rows="3" className='form-control' required></textarea>
//                                                 </div>

//                                                 <div className='col-10 mt-3 mb-3'>
//                                                     <button disabled={phoneError || phone.length !== 10} onClick={handleConsultation} className="consultation1 btn" sx={{ alignitems: "center", cursor: "pointer" }} >PROCEED TO PAY</button>
//                                                 </div>

//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>


//                             </div>
//                             <Typography variant="p" marginTop="20px" marginLeft="60px" fontSize="20px">Contact us:<a href="https://www.youtube.com/" style={{ cursor: "pointer", color: "gray" }}><i className="fa fa-youtube-play" style={{ fontSize: "25px", padding: "10px" }}></i></a>
//                                 <a href="https://www.facebook.com/" style={{ cursor: "pointer", fontWeight: 600, color: "gray" }}><i className="fa fa-facebook" style={{ fontSize: "25px", padding: "10px" }}></i></a>
//                                 <a href="https://www.instagram.com/" style={{ cursor: "pointer", fontWeight: 600, color: "gray" }}><i className="fa fa-instagram" style={{ fontSize: "25px", padding: "10px" }}></i></a>
//                                 <a href="https://www.x.com/" style={{ cursor: "pointer", fontWeight: 600, color: "gray" }}><i className="fa fa-twitter" style={{ fontSize: "25px", padding: "10px" }}></i></a></Typography>
//                         </div>
//                     </div>
//                 </div>
//             </div >
//         </>
//     )
// }

// export default HoroscopeDetails