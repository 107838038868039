import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';

const ChatAstroFile1 = ({ data }) => {

  const navigate = useNavigate();
  // const astrologerChatPages = ["AcharyaJai", "AcharyaShubham", "AcharyaShruti", "AcharyaVinod", "AcharyaKedar"];
  // const handleCardClick = (element, index) => {
  //   const astrologerChatPage = astrologerChatPages[index];
  //   if (astrologerChatPage) {
  //     navigate(`/${astrologerChatPage}`, { state: { element } });
  //   }
  // };
  const astrologerPages = ["AcharyaJai"];
  const [contactNo, setContactNo] = useState('');
  const [astrologers, setAstrologers] = useState([]);

  useEffect(() => {
    const fetchAstrologers = async () => {
      try {
        const response = await fetch("https://app.astrovedaguru.com/api/getAstrologer", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({}),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const jsonData = await response.json();
        console.log("Fetched data:", jsonData);


        setAstrologers(jsonData.recordList);

      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchAstrologers();

  }, []);

  const handleCardClick = (elements) => {
    if (astrologerPages.length > 0) {
      if (elements.contactNo) {
        setContactNo(elements.contactNo);
        sendContactNumber(elements.contactNo);
      }
      const formattedName = elements.name.toLowerCase().replace(/\s+/g, '-'); // Format name to lowercase and replace spaces with dashes
      navigate(`/${formattedName}`, { state: { astrologerData: elements } });
    }
  };

  const sendContactNumber = async (contactNumber) => {
    try {
      const response = await fetch('https://app.astrovedaguru.com/api/loginAppAstrologer', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ contactNo: contactNumber }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const jsonData = await response.json();
      console.log('API response:', jsonData);
     
    } catch (error) {
      console.error('Error sending contact number:', error);
    }
  };


  return (
    <>
      {astrologers.map((elements) => {
        console.log(":Profile Image URL", elements.profileImage); 
        return (
          <div className="col-lg-6 col-md-4 col-sm-12" key={elements.id}>
            <div className="card card8 mb-3" >
              <div className="row g-0">
                <div className="col-3">
                 
                    <img
                      src={`https://app.astrovedaguru.com/${elements.profileImage}`}
                      className="card-img-top"
                      alt=""
                    />
                </div>
                <div className="col-1"></div>
                <div className="col-8">
                  <div className="card8-content">
                    <h5 className="card-title name">{elements.name}</h5>
                    <p className="rates">
                      <CurrencyRupeeIcon sx={{ fontSize: '14px', marginBottom: '3px' }} />
                      {elements.charge}
                    </p>
                  </div>
                  <p className="exp">
                    <b>Experience:</b> {elements.experienceInYears} years
                  </p>
                  <p className="language">
                    <b>Language:</b> {elements.languageKnown}
                  </p>
                  <p className="expertise">
                    <b>Expertise:</b> {elements.primarySkill}
                  </p>
                  <button
                    type="button"
                    className="btn btn-warning buynow"
                    onClick={() => handleCardClick(elements)}
                  >
                    chat now
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default ChatAstroFile1;