export const rashiList = [
    {
        image: "https://tak-astrovedaGuru-av.s3.ap-south-1.amazonaws.com/images/report/20210827094937-monthly_horoscope.webp",
        listname: "Monthly Horoscope",
        rate: "350 only",
        buynow: "Buy Now"
    },
    {
        image: "https://tak-astrovedaGuru-av.s3.ap-south-1.amazonaws.com/images/report/20210827095412-horoscope_compatibility.webp",
        listname: "Horoscope Compatibility",
        rate: "399 only",
        buynow: " Buy Now"
    },
    {
        image: "https://tak-astrovedaGuru-av.s3.ap-south-1.amazonaws.com/images/report/20210827095259-numerology_report.webp",
        listname: "Numerology Report",
        rate: "499 only",
        buynow: "Buy Now"
    },
    {
        image: "https://tak-astrovedaGuru-av.s3.ap-south-1.amazonaws.com/images/report/20210827095322-gem_recommendation_report.webp",
        listname: "Gem Recommendation Report",
        rate: "499 only",
        buynow: "Buy Now"
    },
    {
        image: "https://tak-astrovedaGuru-av.s3.ap-south-1.amazonaws.com/images/report/20210827094736-in_depth_horoscope.webp",
        listname: "In-Depth Horoscope",
        rate: "499 only",
        buynow: "Buy Now"
    },
    {
        image: "https://tak-astrovedaGuru-av.s3.ap-south-1.amazonaws.com/images/report/20210827095146-education_horocope.webp",
        listname: "Education Horoscope",
        rate: "599 only",
        buynow: "Buy Now"
    },
];