import img1 from "./../images/TalkToAstrologer-1.webp";
import img2 from "./../images/PujaSeva.webp";
import img3 from "./../images/ChatWithastrologer.webp";
import img4 from "./../images/DailyPanchang.webp"
import img5 from "./../images/AskQuestion.webp";
import img6 from "./../images/DailyHoroscope.webp"
export const astroCard = [
    {
      imagdata: img1,
      rname: "Talk With Astrologer",
    },
    {
      imagdata: img2,
      rname: "Puja Seva",
    },
    {
      imagdata: img3,
      rname: "Chat With Astrologer",
    },
    {
      imagdata: img4,
      rname: "Daily Panchang",
    },
    {
      imagdata: img5,
      rname: "Ask Question"
    },
    {
      imagdata: img6,
      rname: "Daily Horoscope"
    },
    
  ];
  