export const chatData = [
    {
      imgdat: "../images/suman-1.webp",
      pname: "Acharya Suman Lata",
      rate: "17",
      rati: "4.2",
      chatnow: "Chat Now",
    },
    {
      imgdat: "../images/surinderBala.webp",
      pname: "Acharya Surender Bala",
      rate: "19",
      rati: "5.2",
      chatnow: "Chat Now",
    },
    {
      imgdat: "../images/sushmaTanvar.webp",
      pname: "Acharya Sushma Tanvar",
      rate: "21",
      rati: "4.8",
      chatnow: "Chat Now",
    },
    {
      imgdat: "../images/prashnKundali.webp",
      pname: "Acharya Pavneet Sharma",
      rate: "23",
      rati: "6.2",
      chatnow: "Chat Now",
    },
    {
      imgdat: "../images/anupumaGupta.webp",
      pname: "Acharya Anupuma Gupta",
      rate: "25",
      rati: "4.1",
      chatnow: "Chat Now",
    },
    {
      imgdat: "../images/vandyVershaney-1.webp",
      pname: "Acharya Vandy Varshney",
      rate: "27",
      rati: "5.4",
      chatnow: "Chat Now",
    },
  ];
  