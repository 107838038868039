import React from 'react'
import { useNavigate } from "react-router-dom"
import { Typography, Button } from '@mui/material';
import ArrowBackSharpIcon from '@mui/icons-material/ArrowBackSharp';
const PendingOrder = () => {
    const navigate = useNavigate();
    return (
        <>
            <div className='main-contact'>
                <div className='acharya-ajay'>
                    <Typography variant='h6' fontWeight='700' sx={{ alignItems: 'center', padding: '10px' }}>
                        <ArrowBackSharpIcon
                            fontWeight='bold'
                            sx={{ fontSize: '20px', marginRight: '10px', alignItems: 'center', cursor: 'pointer' }}
                            onClick={() => {
                                navigate('/');
                            }}
                        />
                        Pending Orders
                    </Typography>
                </div>
                <div className='container mt-3'>
                    <div className='pending'>
                        <div>
                            <h5>Product</h5>
                        </div>
                        <div>
                            <h5>Status</h5>
                        </div>
                        <div>
                            <h5>Amount</h5>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default PendingOrder