import img7 from "./../images/astroaries.webp";
import img8 from "./../images/astrotaraus.webp";
import img9 from "./../images/astroGemini.webp";
import img10 from "./../images/astroCancer.webp";
import img11 from "./../images/astroLeo.webp";
import img12 from "./../images/astroVirgo.webp";
import img13 from "./../images/astroLibra.webp";
import img14 from "./../images/astroScorpio.webp";
import img15 from "./../images/astroSagittarius.webp";
import img16 from "./../images/astroCapricorn.webp";

export const dailyData= [
    {
        im: img7,
        name: "Aries"
    },
    {
        im: img8,
        name: "Taurus"
    },
    {
        im: img9,
        name: "Gemini"
    },
    {
        im: img10,
        name: "Cancer"
    },
    {
        im: img11,
        name: "Leo"
    },
    {
        im: img12,
        name: "Virgo"
    },
    {
        im: img13,
        name: "Libra"
    },
    {
        im: img14,
        name: "Scorpio"
    },
    {
        im: img15,
        name: "Sagittarius"
    },
    {
        im: img16,
        name: "Capricorn"
    },
];