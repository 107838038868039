import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'
import { Typography } from '@mui/material';
import ArrowBackSharpIcon from '@mui/icons-material/ArrowBackSharp';
import Banner13 from "../images/Banner13.webp";
import axios from "axios";


const NavgrahaShanti = () => {
    const navigate = useNavigate();

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [phoneError, setPhoneError] = useState("");
    const [message, setMessage] = useState('');

    const handlePhoneChange = (e) => {
        const re = /^[0-9\b]+$/;
        const phoneNumber = e.target.value;

        if (phoneNumber.length === 0 || re.test(phoneNumber)) {
            setPhone(phoneNumber);
            if (phoneNumber.length !== 10) {
                setPhoneError("Please enter a valid 10-digit phone number.");
            } else {
                setPhoneError("");
            }
        }
    };

    const handleConsultation = () => {
        const subject = "Navgraha Shanti Puja";
        const emailMessage = `${message}, Subject: ${subject}`;
        const data = {
            name,
            email,
            phone,
            message: emailMessage,
        };
    
        axios.post('https://app.astrovedaguru.com/api/sendMail', data)
            .then(response => {
                console.log('Email sent successfully:', response.data);
            })
            .catch(error => {
                console.error('Error sending email:', error);
            });
    };
    


    return (
        <>
            <div className='main-panchang'>
                <div className='acharya-ajay'>
                    <Typography variant='h6' fontWeight="700" sx={{ alignitems: "center", padding: "10px" }}><ArrowBackSharpIcon fontWeight="bold" sx={{ fontSize: "20px", marginRight: "10px", alignitems: "center" }} onClick={() => { navigate("/online-puja") }} />Navgraha Shanti Puja</Typography>
                </div>
                <div className='container mt-3'>
                    <div className='row'>
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-5 col-12 mb-1">
                            <div className='card brahaspati'>
                                <img className='graha-image' src={Banner13} alt="...." />
                                <Typography variant='h6' sx={{ marginTop: "15px", marginLeft: "18px", fontWeight: "700", fontSize: "18px" }}>Navgraha Shanti Puja</Typography>
                                <p>Navgraha Shanti Puja is a Hindu prayer or ritual performed to appease the nine planets (Navagrahas). It is believed that by performing this puja, one can gain the blessings of the planets and improve their luck, health, and overall well-being.</p>
                            </div>
                        </div>

                        <div className="col-xl-8 col-lg-8 col-md-8 col-sm-7 col-12 mb-1">
                            <div className="card puja-sevash-1">
                                <Typography variant="h2" fontWeight="500" marginLeft="20px" sx={{ fontSize: "24px", marginTop: "20px" }}>For a FREE puja consultation, kindly fill out the form, and expect a call from us.</Typography>
                                <Typography variant="p" marginLeft="20px" color="red" sx={{ marginTop: "30px" }}>Enter Valid Name</Typography>
                                <input value={name}
                                    onChange={(e) => setName(e.target.value)} className="input-1" type="text" placeholder="Enter your Name" required></input>
                                <Typography variant="p" marginLeft="20px" sx={{ marginTop: "12px" }}>Email (Email is required)*</Typography>
                                <input value={email}
                                    onChange={(e) => setEmail(e.target.value)} className="input-1" type="email" placeholder="Enter your Email" required></input>
                                <Typography variant="p" marginLeft="20px" sx={{ marginTop: "12px" }}>Phone Number*</Typography>
                                <input value={phone}
                                    onChange={handlePhoneChange} className="input-1" type="tel" placeholder="Enter Phone Number" required></input>
                                {phoneError && (
                                    <Typography variant="p" marginLeft="20px" sx={{ color: "red" }}>
                                        {phoneError}
                                    </Typography>
                                )}

                                <Typography variant="p" marginLeft="20px" sx={{ marginTop: "12px" }}>Enter Your Message*</Typography>
                                <textarea value={message} onChange={(e) => setMessage(e.target.value)} name="message" placeholder="Enter your message" rows="3" className='input-1' required></textarea>
                                <button disabled={phoneError || phone.length !== 10}
                                    onClick={handleConsultation} className="consultation" sx={{ alignitems: "center", cursor: "pointer" }} >Free Puja Consultation</button>
                            </div>
                            <Typography variant="p" marginTop="20px" marginLeft="60px" fontSize="20px">Contact us:<a href="https://www.youtube.com/" style={{ cursor: "pointer", color: "gray" }}><i className="fa fa-youtube-play" style={{ fontSize: "25px", padding: "10px" }}></i></a>
                                <a href="https://www.facebook.com/" style={{ cursor: "pointer", fontWeight: 600, color: "gray" }}><i className="fa fa-facebook" style={{ fontSize: "25px", padding: "10px" }}></i></a>
                                <a href="https://www.instagram.com/" style={{ cursor: "pointer", fontWeight: 600, color: "gray" }}><i className="fa fa-instagram" style={{ fontSize: "25px", padding: "10px" }}></i></a>
                                <a href="https://www.x.com/" style={{ cursor: "pointer", fontWeight: 600, color: "gray" }}><i className="fa fa-twitter" style={{ fontSize: "25px", padding: "10px" }}></i></a></Typography>
                            <Typography variant="h5" marginTop="20px" marginLeft="80px" fontSize={25}>Terms of Service | Privacy Policy</Typography>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default NavgrahaShanti;