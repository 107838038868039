import React,{useState, useEffect}from 'react'
import "./Style.css";
import { useNavigate } from "react-router-dom";
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import StarIcon from "@mui/icons-material/Star";

const ChatAstroFile = ({ data }) => {
  const navigate = useNavigate();
   
     const astrologerPages = ["AcharyaJai"]
  // const [astrologerPages, setAstrologerPages] = useState(["AcharyaJai"]);
    const [contactNo, setContactNo] = useState('');
  const [astrologerPage, setAstrologerPage] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://app.astrovedaguru.com/api/getAstrologer', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({}) 
        });
        
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        
        const data = await response.json();


        if (Array.isArray(data.recordList)) {
          const slicedData = data.recordList.slice(0, 6);
          setAstrologerPage(slicedData);
        } else {
          console.error('Expected array, received:', data.recordList);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

        
  
  const handleCardClick = (element) => {
    if (astrologerPages.length > 0) {
      if (element.contactNo) {
        setContactNo(element.contactNo);
        sendContactNumber(element.contactNo);
      }
      
      const formattedName = element.name.toLowerCase().replace(/\s+/g, '-'); // Format name to lowercase and replace spaces with dashes
      navigate(`/${formattedName}`, { state: { astrologerData: element } });
    }
  };

  const sendContactNumber = async (contactNumber) => {
    try {
      const response = await fetch('https://app.astrovedaguru.com/api/loginAppAstrologer', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ contactNo: contactNumber }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const jsonData = await response.json();
      console.log('API response:', jsonData);
    } catch (error) {
      console.error('Error sending contact number:', error);
    }
  };




  return (
    <>
      {astrologerPage.map((element, index) => (
        <div className="col-xl-2 col-lg-2 col-md-3 col-sm-6 col-xs-6 col-12 mb-1 card5" onClick={() => handleCardClick(element)} key={index}>
          <img src={`https://app.astrovedaguru.com/${element.profileImage}`} className="card-img-top imgdat" alt="..." />
          <div className="card-body">
          {/* <p className="rating-1">
              <span><StarIcon  sx={{fontSize:"17px", color:"#FDDA0D"}}/>{element.reportRate}</span>
            </p> */}
            <p className="card-title rname">{element.name}</p>
            <p><span><StarIcon sx={{ fontSize: "18px", color: "#d73878" }} /><StarIcon sx={{ fontSize: "18px", color: "#d73878" }} /><StarIcon sx={{ fontSize: "18px", color: "#d73878" }} /><StarIcon sx={{ fontSize: "18px", color: "#d73878" }} /><StarIcon sx={{ fontSize: "18px", color: "#d73878" }} /></span></p>
            <p className="rate"><span style={{ fontWeight: "800" }}>
              <CurrencyRupeeIcon sx={{ fontSize: "17px", marginBottom: "3px" }} />
            </span><span style={{ fontWeight: "700" }}>{element.charge}</span>/min</p>

            <div className="card-btn">
              <button type="button" className="btn"><i className="fa fa-whatsapp" style={{ fontSize: "24px" }}></i></button>
              <span className='span-3'>Chat Now</span>
              {/* {element.chatStatus ? 'Online' : 'Offline'} */}
            </div>
          </div>
          <div className="extra"></div>
        </div>
      ))}
    </>
  );
};

export default ChatAstroFile;