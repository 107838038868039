
const initialState = {
  fun: null
};

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'STORE_FUN':
      return {
        ...state,
        fun: action.payload
      };
    default:
      return state;
  }
};

export default rootReducer;
