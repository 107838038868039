import React from 'react'
import {useNavigate} from "react-router-dom"
import CardHeader from '@mui/material/CardHeader';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import AdjustOutlinedIcon from '@mui/icons-material/AdjustOutlined';
import HelpCenterOutlinedIcon from '@mui/icons-material/HelpCenterOutlined';
import { Button, Typography, FormControl, InputLabel, Select, MenuItem } from "@mui/material"
import ArrowBackSharpIcon from '@mui/icons-material/ArrowBackSharp';
const AskQuestion = () => {
    const navigate = useNavigate();
    return (
        <>
            <div className='main-panchang'>
                <div className='acharya-ajay'>
                    <Typography variant='h6' fontWeight="700" sx={{ alignitems: "center", padding: "10px" }}><ArrowBackSharpIcon fontWeight="bold" sx={{ fontSize: "20px", marginRight: "10px", alignitems: "center" }} onClick={() => { navigate("/") }} />Ask Question</Typography>
                </div>
                <div className='main'>
                    <div className='main-ask-header'>
                        <div className='ask-header'>
                            <Typography className ="fm" variant='h6' fontWeight="600" sx={{ marginTop: 1 }}>< CurrencyRupeeIcon className='currency'/>99(for 1 Question)</Typography>
                            <Button className="fg" sx={{ marginTop: 1, borderRadius: 3, color:'#d73878',  backgroundColor:"white"}} variant='contained' >Ask Now</Button>
                        </div>
                    </div>
                    <div className="choose-category">
                        <Typography variant='h6' fontWeight="700" sx={{ marginTop: 6, padding:"10px"}}>Choose Category</Typography>
                        <FormControl marginLeft="50"  sx={{ width: "100%" }}>
                            <InputLabel id="menu" >Category</InputLabel>
                            <Select labelId="menu" id="menu-list" label="courses">
                                <MenuItem fontWeight="700" value={'love'}>Love</MenuItem>
                                <MenuItem value={'bussiness'}>Bussiness</MenuItem>
                                <MenuItem value={"work"}>Work</MenuItem>
                                <MenuItem value={"education"}>Education</MenuItem>
                                <MenuItem value={"self"}>Self</MenuItem>
                                <MenuItem value={"life"}>Life</MenuItem>
                                <MenuItem value={"money"}>Money</MenuItem>
                            </Select>
                            {/* <Typography variant='h6' fontWeight="700" sx={{ marginTop: 2 }}>Select Profile</Typography>
                            <InputLabel id="menu" >Category</InputLabel>
                            <Select labelId="menu" id="menu-list" label="courses">
                            </Select> */}
                        </FormControl>
                    </div>
                    <div className="question-here">
                        <textarea rows="3" cols="33">type a qyestion here</textarea>
                        <br></br>
                        <br></br>
                        <Typography variant='h6' fontWeight={"700"}>Ideas what to Ask (Select Any)</Typography>
                        <br></br>
                        <Typography variant='p'>< HelpCenterOutlinedIcon sx={{ backgroundColor: "white", color: "orange", fontSize: "20px" }} />What is the best field for me to study?</Typography>
                        <hr />
                        <Typography variant='p'>< HelpCenterOutlinedIcon sx={{ backgroundColor: "white", color: "orange", fontSize: "20px" }} />Will I get admission in my choice of college?</Typography>
                        <hr />
                        <Typography variant='p'>< HelpCenterOutlinedIcon sx={{ backgroundColor: "white", color: "orange", fontSize: "20px" }} />Will I get selected in medical college this year?</Typography>
                        <hr />
                        <Typography variant='p'>< HelpCenterOutlinedIcon sx={{ backgroundColor: "white", color: "orange", fontSize: "20px" }} />Is doing engineering good for my career?</Typography>
                        <hr />
                        <Typography variant='p'>< HelpCenterOutlinedIcon sx={{ backgroundColor: "white", color: "orange", fontSize: "20px" }} />How will I perform in 10 boards this year?</Typography>
                        <hr />
                        <Typography variant='p'>< HelpCenterOutlinedIcon sx={{ backgroundColor: "white", color: "orange", fontSize: "20px" }} />Should I opt for science or commerce? What is best for me?</Typography>
                        <hr />
                        <Typography variant='p'>< HelpCenterOutlinedIcon sx={{ backgroundColor: "white", color: "orange", fontSize: "20px" }} />What do my classmates think of me?</Typography>
                        <hr />
                        <Typography variant='p'>< HelpCenterOutlinedIcon sx={{ backgroundColor: "white", color: "orange", fontSize: "20px" }} />What does my school friends thing of me?</Typography>
                        <hr />
                        <Typography variant='p'>< HelpCenterOutlinedIcon sx={{ backgroundColor: "white", color: "orange", fontSize: "20px" }} />I failed this year, what went wrong?</Typography>
                        <hr />
                        <Typography variant='p'><HelpCenterOutlinedIcon sx={{ backgroundColor: "white", color: "orange", fontSize: "20px" }} />Will I pass this year? I am nervous</Typography>
                        <hr />
                        <Typography variant='p'>< HelpCenterOutlinedIcon sx={{ backgroundColor: "white", color: "orange", fontSize: "20px" }} />I got selected in 2 colleges. Which is the best college for me?</Typography>
                        <hr />
                        <Typography variant='p'><HelpCenterOutlinedIcon sx={{ backgroundColor: "white", color: "orange", fontSize: "20px" }} />I graduated last year but unable to find a job? Please advice.</Typography>
                        <hr />
                        <Typography variant='p'>< HelpCenterOutlinedIcon sx={{ backgroundColor: "white", color: "orange", fontSize: "20px" }} />Will I be able to go abroad for higher education?</Typography>
                    </div>
                    <div className='choose-question'>
                        <Typography variant='h6' fontWeight={"700"}>Why Choose astrovedaGuru</Typography>
                        <Typography variant='p'>< AdjustOutlinedIcon sx={{ backgroundColor: "white", color: "orange", fontSize: "16px" }} />Personalized responses provided by our team of Vedic astrologers within 24 hours.</Typography>
                        <br></br>
                        <Typography variant='p'> < AdjustOutlinedIcon sx={{ backgroundColor: "white", color: "orange", fontSize: "16px", }} />Qualified and experienced astrologers will look into your birth chart and provide the right guidance</Typography>
                        <br></br>
                        <Typography variant='p'> < AdjustOutlinedIcon sx={{ backgroundColor: "white", color: "orange", fontSize: "16px" }} />You can seek answers to any part of your life and for most pressing issues.</Typography>
                        <br></br>
                        <Typography variant='p'> < AdjustOutlinedIcon sx={{ backgroundColor: "white", color: "orange", fontSize: "16px" }} />Our team of Vedic astrologers will not just provide answers but also suggest a remedial solution.</Typography>
                    </div>
                </div>
            </div>
        </>
    )
}
export default AskQuestion