
import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { dailyData } from "./DailyData";
import axios from "axios";
const DailyFileSearch = () => {
  const [dailysData, setDailyData] = useState(dailyData);
  const navigate = useNavigate();

  const [data1, setData1] = useState([]);

  useEffect(() => {
    const fetchHoroscopeSigns = async () => {
      try {
        const response = await axios.post('https://app.astrovedaguru.com/api/getHororscopeSign');
        const filteredData = response.data.recordList.filter(item => item.id >= 1 && item.id <= 11).sort((a, b) => a.id - b.id);
        setData1(filteredData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchHoroscopeSigns();
  }, []);
  
  
  const rashiDetailsClick = (product) => {
    navigate(`/daily-horoscope`, { state: { rashiDetails: product, horoscopeSignId: product.id } });
  };

  return (
    <div className='container mt-4'>
      <div className="dailyscope">
        <div className="daily-content">
          <h5>Daily Horoscopes</h5>
          <a className="seeyours" style={{ cursor: "pointer", fontWeight: "700", color: " #d73878" }} onClick={() => { (navigate('/DailyRashiSearch')) }}>SEE YOURS<i className="fa fa-chevron-right" style={{ fontSize: "14px", padding: "5px" }}></i></a>
        </div>
        <p>Read and start your day</p>
        <section className="item-section mt-4 container">
          <div className="row talkdata">
            <div className='daily-horoscop'>
              {data1.map((element, index) => (
                <div className="card6" style={{ backgroundColor: "#f8d9e4", cursor: "pointer" }} key={index} onClick={() => rashiDetailsClick(element)}>
                  <div className="card-header">
                    <img src={`https://app.astrovedaguru.com/${element.image}`} className="imdata" alt="..." />
                  </div>
                  <div className="card-body">
                    <h5 className="card-title name">{element.name}</h5>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
    </div>

  );
};

export default DailyFileSearch;