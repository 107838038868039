import React from 'react';
import { Typography } from '@mui/material';
import { useNavigate } from "react-router-dom";
import ArrowBackSharpIcon from '@mui/icons-material/ArrowBackSharp';
const DailyHoroscope = () => {
    const navigate = useNavigate();
    return (
        <>
            <div className='main-terms'>
                <div className='terms-conditions'>
                    <Typography variant='h6' fontWeight="700" sx={{ marginTop: 3, marginLeft: "40px" }}><ArrowBackSharpIcon fontWeight="900" sx={{ fontSize: "20px" }} onClick={() => { navigate("/") }} />Today's Horoscope</Typography>
                </div>
            </div>

        </>
    )
}

export default DailyHoroscope