import React, { useState } from 'react';
import { astrologerData } from "./AstrologerCard";
import AstrologerCardFile from "./AstrologerCardFile";

import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";
import ArrowBackSharpIcon from '@mui/icons-material/ArrowBackSharp';

const AstrologerCardSearch = () => {

  const navigate = useNavigate();

  const [astrologersData, setAstrologerData] = useState(astrologerData);


  return (
    <>

      <div className='main-contact'>
        <div className='acharya-ajay'>
          <Typography variant='h6' fontWeight="700" sx={{ alignitems: "center", padding: "10px" }}><ArrowBackSharpIcon fontWeight="bold" sx={{ fontSize: "20px", marginRight: "10px", alignitems: "center" }} onClick={() => { navigate("/") }} />Talk To Astrologer</Typography>
        </div>

        <div className="astrologer">
          <section className="item-section mt-4 container">
            <div className="row">
              <AstrologerCardFile data={astrologersData} className="col-6 mb-2" />
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default AstrologerCardSearch;
