export const astroData = [
    {
        imgdata: "../images/suman-1.webp",
        rname: 'Acharya Suman Lata',
        rate: "21",
        rating: "4.2",
        talknow: "Talk Now"
    },
    {
        imgdata: '../images/surinderBala.webp',
        rname: 'Acharya Surinder Bala',
        rate: "25",
        rating: "5.2",
        talknow: "Talk Now"

    },
    {
        imgdata: "../images/sushmaTanvar.webp",
        rname: "Acharya Sushma Tanvar",
        rate: "27",
        rating: "4.3",
        talknow: "Talk Now"
    },
    {
        imgdata: "../images/prashnKundali.webp",
        rname: "Acharya Pavneet Sharma",
        rate: "29",
        rating: "4.7",
        talknow: "Talk Now"
    },
    {
        imgdata: "../images/anupumaGupta.webp",
        rname: "Acharya Anupuma Gupta",
        rate: "23",
        rating: "4.4",
        talknow: "Talk Now"
    },
    {
        imgdata: '../images/vandyVershaney-1.webp',
        rname: "Acharya Vandy Varshney",
        rate: "20",
        rating: "4.8",
        talknow: "Talk Now "
    }
];