import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Typography } from '@mui/material';
import ArrowBackSharpIcon from '@mui/icons-material/ArrowBackSharp';
import Banner4 from "../images/Brihaspati-Graha-Shanti-Puja1.jpg";
import axios from "axios";

const BrahaspatiGraha = () => {

    const navigate = useNavigate();
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [phoneError, setPhoneError] = useState("");
    const [message, setMessage] = useState('');

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        name: '',
        contactNo: '',
        gender: '',
        dob: '',
        tob: '',
        emailAddress: '',
        language: '',
        maritalStatus: '',
        birthPlace: ''
    });

    useEffect(() => {
        const storedData = {
            firstName: localStorage.getItem('firstName') || '',
            lastName: localStorage.getItem('lastName') || '',
            name: localStorage.getItem('userName') || '',
            contactNo: localStorage.getItem('contactNo') || '',
            gender: localStorage.getItem('gender') || '',
            dob: localStorage.getItem('birthDate') || '',
            tob: localStorage.getItem('birthTime') || '',
            emailAddress: localStorage.getItem('email') || '',
            language: localStorage.getItem('language') || '',
            maritalStatus: localStorage.getItem('maritalStatus') || '',
            birthPlace: localStorage.getItem('birthPlace') || ''
        };
        setFormData(storedData);
        setName(storedData.name);
        setEmail(storedData.emailAddress);
        setPhone(storedData.contactNo);
    }, []); 

    const handlePhoneChange = (e) => {
        const re = /^[0-9\b]+$/;
        const phoneNumber = e.target.value;

        if (phoneNumber.length === 0 || re.test(phoneNumber)) {
            setPhone(phoneNumber);
            if (phoneNumber.length !== 10) {
                setPhoneError("Please enter a valid 10-digit phone number.");
            } else {
                setPhoneError("");
            }
        }
    };

    const handleConsultation = () => {
        const subject = "Brihaspati Graha Shanti Puja";
        const emailMessage = `${message}, Subject: ${subject}`;
        const data = {
            name,
            email,
            phone,
            message: emailMessage,
        };

        axios.post('https://app.astrovedaguru.com/api/sendMail', data)
            .then(response => {
                console.log('Email sent successfully:', response.data);
            })
            .catch(error => {
                console.error('Error sending email:', error);
            });
    };
    
    const location = useLocation();
    const astrologerCard = location.state?.astrologerCard;
    
    return (
        <>
            <div className='main-panchang'>
                <div className='acharya-ajay'>
                    <Typography variant='h6' fontWeight="700" sx={{ alignitems: "center", padding: "10px" }}><ArrowBackSharpIcon fontWeight="bold" sx={{ fontSize: "20px", marginRight: "10px", alignitems: "center" }} onClick={() => { navigate("/online-puja") }} />{astrologerCard.name}</Typography>
                </div>
                <div className='container mt-3'>
                    <div className='row'>
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-5 col-12 mb-1">
                            {/* {astroProduct && ( */}
                                <div className='card brahaspati'>
                                    <img className='graha-image' src={`https://app.astrovedaguru.com/${astrologerCard.productImage}`} alt={astrologerCard.name} />
                                    <Typography variant='h6' sx={{ marginTop: "15px", marginLeft: "18px", fontWeight: "700", fontSize: "18px" }}>{astrologerCard.name}</Typography>
                                    <p>{astrologerCard.features}</p>
                                </div>
                            {/* )} */}
                        </div>
                        <div className="col-xl-8 col-lg-8 col-md-8 col-sm-7 col-12 mb-1">
                            <div className="card puja-sevash-1">
                                {/* <Typography variant="h2" fontWeight="500" marginLeft="20px" sx={{ fontSize: "24px", marginTop: "20px" }}>For a FREE puja consultation, kindly fill out the form, and expect a call from us.</Typography> */}
                                <Typography variant="p" marginLeft="20px" sx={{ marginTop: "30px" }}>Enter Name</Typography>
                                <input value={name}
                                    onChange={(e) => setName(e.target.value)} className="input-1" type="text" placeholder="Enter your Name" required></input>
                                <Typography variant="p" marginLeft="20px" sx={{ marginTop: "12px" }}>Enter Email</Typography>
                                <input value={email}
                                    onChange={(e) => setEmail(e.target.value)} className="input-1" type="email" placeholder="Enter your Email" required></input>
                                <Typography variant="p" marginLeft="20px" sx={{ marginTop: "12px" }}>Phone Number</Typography>
                                <input value={phone}
                                    onChange={handlePhoneChange} className="input-1" type="tel" placeholder="Enter Phone Number" required></input>
                                {phoneError && (
                                    <Typography variant="p" marginLeft="20px" sx={{ color: "red" }}>
                                        {phoneError}
                                    </Typography>
                                )}

                                <Typography variant="p" marginLeft="20px" sx={{ marginTop: "12px" }}>Enter Your Message</Typography>
                                <textarea value={message} onChange={(e) => setMessage(e.target.value)} name="message" placeholder="Enter your message" rows="3" className='input-1' required></textarea>

                                <button disabled={phoneError || phone.length !== 10} onClick={handleConsultation} className="consultation" sx={{ alignitems: "center", cursor: "pointer" }} >Free Puja Consultation</button>
                            </div>
                            <Typography variant="p" marginTop="20px" marginLeft="60px" fontSize="20px">Contact us:<a href="https://www.youtube.com/" style={{ cursor: "pointer", color: "gray" }}><i className="fa fa-youtube-play" style={{ fontSize: "25px", padding: "10px" }}></i></a>
                                <a href="https://www.facebook.com/" style={{ cursor: "pointer", fontWeight: 600, color: "gray" }}><i className="fa fa-facebook" style={{ fontSize: "25px", padding: "10px" }}></i></a>
                                <a href="https://www.instagram.com/" style={{ cursor: "pointer", fontWeight: 600, color: "gray" }}><i className="fa fa-instagram" style={{ fontSize: "25px", padding: "10px" }}></i></a>
                                <a href="https://www.x.com/" style={{ cursor: "pointer", fontWeight: 600, color: "gray" }}><i className="fa fa-twitter" style={{ fontSize: "25px", padding: "10px" }}></i></a></Typography>
                            <Typography variant="h5" marginTop="20px" marginLeft="80px" fontSize={25}>Terms of Service | Privacy Policy</Typography>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default BrahaspatiGraha;