import React from "react";
import { useNavigate } from "react-router-dom"
import "./Style.css";
const Cards = ({ data }) => {
  const navigate = useNavigate();

  const targetPages = ["AstrologerCardSearch", "Puja-Seva", "ChatAstroSearch1", "Daily-Panchang", "Ask-Question", "DailyRashiSearch"];
  const handleCardClick = (element, index) => {
    const targetPage = targetPages[index];
    if (targetPage) {
      navigate(`/${targetPage}`, { state: { element } });
    }
  };
   

  return (
    <>
      {data.map((element, index) => (
        <div className="col-xl-2 col-lg-3 col-md-3 col-sm-6 col-xs-12 mb-2" key={index}>
          <div className="card card-1" style={{ cursor: "pointer" }} onClick={() =>  handleCardClick(element, index)}>
            <img src={element.imagdata} className="card-img-top" alt="..." />
            <div className="extra" ></div>
          </div>
          <div className="card-body card-1-content">
            <h5 className="card-title">{element.rname}</h5>
          </div>
        </div>
      ))}
    </>
  );
};

export default Cards;
