import React, {useState, useEffect} from 'react'
// import Banner from "../images/DesktopBanner1.webp";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
const OnlinePuja = () => {
    const navigate = useNavigate();

    const [bannerTypeImage, setBannerTypeImage] = useState(null);

    useEffect(() => {
        fetchData();
      }, []);
    
      const fetchData = async () => {
        try {
          const response = await axios.post('https://app.astrovedaguru.com/api/getBanner');
         
          const bannerData = response.data.recordList.find((element, index) => index === 1); // Find image at index 3
          if (bannerData) {
            setBannerTypeImage(`https://app.astrovedaguru.com/${bannerData.bannerImage}`);
          } else {
            console.error('Banner image at index 1 not found.');
          }
        } catch (error) {
          console.error('Error fetching data: ', error);
        }
      }; 
      const handleNavigate = (astrologerName) => {
        const formattedName = astrologerName.toLowerCase().replace(/\s+/g, '-');
        navigate(`/${formattedName}`);
    };

    return (
        <>
            <div className='container mt-4'>
            {bannerTypeImage && (
          <img
            src={bannerTypeImage}
            className="banner"
            alt="Banner"
            onClick={() => (navigate("/online-puja"))}
          />
        )}
            </div>
        </>
    )
}

export default OnlinePuja;
