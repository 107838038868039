import React, {useState, useEffect} from "react";
import { Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackSharpIcon from '@mui/icons-material/ArrowBackSharp';
import ganesh from "../images/ganesh.webp";
import ganesh1 from "../images/ganesh-1.webp";
import ganesh2 from "../images/ganesh-2.webp";
import ganesh3 from "../images/ganesh-3.webp";
import hanesh4 from "../images/ganesh-4.webp";
import axios from "axios";
const PujaSeva= () => {
    const navigate = useNavigate();
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [message, setMessage] = useState('');
    const [phoneError, setPhoneError] = useState("");
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        name: '',
        contactNo: '',
        gender: '',
        dob: '',
        tob: '',
        emailAddress: '',
        language: '',
        maritalStatus: '',
        birthPlace: ''
    });

    useEffect(() => {
        const storedData = {
            firstName: localStorage.getItem('firstName') || '',
            lastName: localStorage.getItem('lastName') || '',
            name: localStorage.getItem('userName') || '',
            contactNo: localStorage.getItem('contactNo') || '',
            gender: localStorage.getItem('gender') || '',
            dob: localStorage.getItem('birthDate') || '',
            tob: localStorage.getItem('birthTime') || '',
            emailAddress: localStorage.getItem('email') || '',
            language: localStorage.getItem('language') || '',
            maritalStatus: localStorage.getItem('maritalStatus') || '',
            birthPlace: localStorage.getItem('birthPlace') || ''
        };
        setFormData(storedData);
        setName(storedData.name);
        setEmail(storedData.emailAddress);
        setPhone(storedData.contactNo);
    }, []); 


    const handleConsultation = () => {
        const data = {
            name: name,
            email: email,
            phone: phone,
            message: message
        };

        axios.post('https://app.astrovedaguru.com/api/sendMail', data)
            .then(response => {
                console.log(response.data);  
                alert("Consultation request submitted successfully!");
                setName("");
                setEmail("");
                setPhone("");
                setMessage("");
            })
            .catch(error => {
                console.error('Error submitting consultation:', error);
                alert("Failed to submit consultation request. Please try again later.");
            });
    };

    const handlePhoneChange = (e) => {
        const re = /^[0-9\b]+$/;
        const phoneNumber = e.target.value;

        if (phoneNumber.length === 0 || re.test(phoneNumber)) {
            setPhone(phoneNumber);
            if (phoneNumber.length !== 10) {
                setPhoneError("Please enter a valid 10-digit phone number.");
            } else {
                setPhoneError("");
            }
        }
    };
    return (
        <>
            <div className="main-contact">
                <div className='acharya-ajay'>
                    <Typography variant='h6' fontWeight="700" sx={{ alignitems: "center", padding: "10px" }}><ArrowBackSharpIcon fontWeight="bold" sx={{ fontSize: "20px", marginRight: "10px", alignItems: "center" }} onClick={() => { navigate("/") }} />Puja Seva</Typography>
                </div>
                <div className="container mt-4">
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-1 scroller">
                            <div className="card puja-sevash">
                                <img src={ganesh} />
                            </div>
                            <Typography variant='h3' color="#414449" sx={{ marginTop: "15px", fontSize:"16px"}}>In Vedic Astrology, Puja is often recommended in various situations to seek divine intervention and favorable cosmic energies. The practice of Puja is believed to mitigate negative planetary influences and enhance positive ones clearing self-doubts and providing confidence to overcome obstacles.</Typography>
                            <Typography variant="h3" sx={{ marginTop: "20px", fontSize: "25px", fontWeight: "bold" }}>Puja for You</Typography>
                            <Typography variant="h3" color="#414449" sx={{ marginTop: "20px", fontSize: "16px" }}>All Puja's are good, but the best results can be achieved if Puja is performed on the basis of your birth charts, your Lagna, your Rashi and keeping in mind the strengths and weakness of planets in your Kundli.</Typography>
                            <Typography variant="h3" color="black" sx={{ marginTop: "20px", fontSize: "18px" }}>Follow these steps for a personalised puja consultation:</Typography>
                            <div className="puja-container scrollers">
                                <div className="row puja-step">
                                    <div className="card step-1">
                                        <div className="step-2">
                                            <img src={ganesh2} />
                                        </div>
                                    </div>
                                </div>
                                <div className="puja-step">
                                    <div className="card step-1">
                                        <div className="step-2"><img src={ganesh3} /></div>
                                    </div>
                                </div>
                                <div className="puja-step">
                                    <div className="card step-1">
                                        <div className="step-2"><img src={hanesh4} /></div>
                                    </div>
                                </div>
                            </div>
                            <Typography variant="h3" sx={{ marginTop: "35px", fontSize: "25px", fontWeight: "bold" }}>How it works</Typography>
                            <Typography variant="h3" color="#414449" sx={{ marginTop: "20px", fontSize: "16px" }}>Our experts will advise you the best suited Puja basis your lagna kundli, dosh, dash, and malicious planets that bring negativity in life. This divine interaction will not only bring positive energies but also provide inner strength and clarity to overcome all obstacles of life.</Typography>
                            <div className="card ganesh-1">
                                <img src={ganesh1} />
                            </div>
                            <Typography variant="h3" color="#414449" sx={{ marginTop: "20px", fontSize: "16px" }}>Good time don't last forever, the reason is the movement. Life is marked by planetary transitions such as Saturn's Sade Sati or Rahu-Ketu transits. These periods can bring challenges or changes. Performing Puja during such phases is believed to lessen difficulties, enhance opportunities and bring success in most difficult times</Typography>
                            <Typography variant="h3" color="#414449" sx={{ marginTop: "20px", fontSize: "16px" }}>Our journey so far in delivering blessings.</Typography>
                            <div className="card collection">
                                <div className="row ppd">
                                    <div className="col-4">
                                        <Typography variant="p" sx={{fontSize:"25px", marginLeft:"20px",fontWeight:"800", color:"#d73878"}}>500+</Typography>
                                        <p style={{marginLeft:"20px"}}>Pandits</p>
                                    </div>

                                    <div className="col-4">
                                        <Typography variant="p" sx={{fontSize:"25px", fontWeight:"800", color:"#d73878"}}>1000+</Typography>
                                        <p>Puja Performed</p>
                                    </div>

                                    <div className="col-4">
                                        <Typography variant="p" sx={{fontSize:"25px", fontWeight:"800", color:"#d73878", marginLeft:"30px"}}>1 Mns</Typography>
                                        <p style={{marginLeft:"30px"}}>Devotees</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-1 mt-5">
                            <div className="card puja-sevash-1">
                                <Typography variant="h2" fontWeight="500" marginLeft="20px" sx={{ fontSize: "24px", marginTop: "20px" }}>For a FREE puja consultation, kindly fill out the form, and expect a call from us.</Typography>
                                <Typography variant="p" marginLeft="20px" sx={{ marginTop: "30px" }}>Enter Valid Name</Typography>
                                <input value={name}
                                    onChange={(e) => setName(e.target.value)} className="input-1" type="text" placeholder="Enter your Name" required></input>
                                <Typography variant="p" marginLeft="20px" sx={{ marginTop: "12px" }}>Email (Email is required)*</Typography>
                                <input value={email}
                                    onChange={(e) => setEmail(e.target.value)} className="input-1" type="email" placeholder="Enter your Email" required></input>
                                <Typography variant="p" marginLeft="20px" sx={{ marginTop: "12px" }}>Phone Number*</Typography>
                                <input  value={phone}
                                    onChange={handlePhoneChange} className="input-1" type="tel" placeholder="Enter Phone Number" required></input>
                                    {phoneError && (
                                    <Typography variant="p" marginLeft="20px" sx={{ color: "red" }}>
                                        {phoneError}
                                    </Typography>
                                )}
                                <textarea value={message}
                                    onChange={(e) => setMessage(e.target.value)} name="message" placeholder="Enter your message" rows="3" className='input-1' required></textarea>
                                <button disabled={phoneError || phone.length !== 10}    onClick={handleConsultation} className="consultation"  sx={{ alignitems: "center", cursor:"pointer"}} >Free Puja Consultation</button>
                            </div>
                            <Typography variant="p" marginTop="20px" marginLeft="60px" fontSize="20px">Contact us:<a href="https://www.youtube.com/" style={{ cursor: "pointer", color: "gray" }}><i className="fa fa-youtube-play" style={{ fontSize: "25px", padding: "10px" }}></i></a>
                                <a href="https://www.facebook.com/" style={{ cursor: "pointer", fontWeight: 600, color: "gray" }}><i className="fa fa-facebook" style={{ fontSize: "25px", padding: "10px" }}></i></a>
                                <a href="https://www.instagram.com/" style={{ cursor: "pointer", fontWeight: 600, color: "gray" }}><i className="fa fa-instagram" style={{ fontSize: "25px", padding: "10px" }}></i></a>
                                <a href="https://www.x.com/" style={{ cursor: "pointer", fontWeight: 600, color: "gray" }}><i className="fa fa-twitter" style={{ fontSize: "25px", padding: "10px" }}></i></a></Typography>
                            {/* <Typography variant="h5" marginTop="20px" marginLeft="80px" fontSize={25}>Terms of Service | Privacy Policy</Typography> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PujaSeva;
