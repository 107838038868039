export const detailsData = [
    {
        mgdata: "https://static.astrovedaGuru.com/astro-images/agents/c55.jpg",
        rnames: 'Acharya Jai Shashtri',
        exp: '10 year',
        language: "English, Hindi",
        expertise: "Vedic, Lal Kitab, Horary",
        rates: "25/Min",
        callnow: "Chat Now"
    },
    {
        mgdata: 'https://static.astrovedaGuru.com/astro-images/agents/Astrosubham%20ajaymedium.jpg',
        rnames: 'Acharya Shubham',
        exp: '10 year',
        language: "English, Hindi",
        expertise: "Vedic, Vastu, KP",
        rates: "25/Min",
        callnow: "Chat Now"
    },
    {
        mgdata: "https://static.astrovedaGuru.com/astro-images/agents/c31.jpg",
        rnames: "Tarot Shruti",
        exp: '18 year',
        language: "English, Hindi",
        expertise: "Tarot",
        rates: "27/Min",
        callnow: "Chat Now"
    },
    {
        mgdata: "https://static.astrovedaGuru.com/astro-images/agents/3.png",
        rnames: "Acharya vinod",
        exp: '27 year',
        language: "Hindi",
        expertise: "Vedic, Numerology, KP",
        rates: "31/Min",
        callnow: "Chat Now"
    },
    {
        mgdata: "https://static.astrovedaGuru.com/astro-images/agents/kedar124.jpg",
        rnames: "Acharya Kedar",
        exp: '27 year',
        language: "English, Hindi",
        expertise: "Vedic, Prashana",
        rates: "25/Min",
        callnow: "Chat Now"
    },
    {
        mgdata: 'https://static.astrovedaGuru.com/astro-images/agents/37.png',
        rnames: "Acharya ArvidS",
        exp: '10 year',
        language: "English, Hindi",
        expertise: "Vedic, Vastu, KP",
        rates: "100/Min",
        callnow: "Chat Now"
    },
    {
        mgdata: 'https://static.astrovedaGuru.com/astro-images/agents/shipra-124.jpg',
        rnames: "Acharya Shipra",
        exp: '10 year',
        language: "English, Hindi",
        expertise: "Vedic, Numerology, Tarot",
        rates: "25/Min",
        callnow: "Chat Now"
    },
    {
        mgdata: 'https://static.astrovedaGuru.com/astro-images/agents/c46.jpg',
        rnames: "Acharya PankajK",
        exp: '10 year',
        language: "English, Hindi",
        expertise: "Vedic, Vastu, Palmistry",
        rates: "25/Min",
        callnow: "Chat Now"
    },
    {
        mgdata: 'https://static.astrovedaGuru.com/astro-images/agents/10.png',
        rnames: "Acharya Ajay",
        exp: '21 year',
        language: "English, Hindi",
        expertise: "Vedic",
        rates: "25/Min",
        callnow: "Chat Now"
    },
    {
        mgdata: 'https://static.astrovedaGuru.com/astro-images/agents/shivpraksash%20124.jpg',
        rnames: "Acharya ShivP",
        exp: '10 year',
        language: "English, Hindi",
        expertise: "Vedic",
        rates: "25/Min",
        callnow: "Chat Now"
    },
    {
        mgdata: 'https://static.astrovedaGuru.com/astro-images/agents/c41.jpg',
        rnames: "Acharya Subramanaya",
        exp: '12 year',
        language: "English, Hindi",
        expertise: "Vedic",
        rates: "25/Min",
        callnow: "Chat Now"
    },
    {
        mgdata: 'https://static.astrovedaGuru.com/astro-images/agents/c44.jpg',
        rnames: "Tarot Rashmi",
        exp: '10 year',
        language: "English, Hindi",
        expertise: "Vedic, Numerology, Torat",
        rates: "25/Min",
        callnow: "Chat Now"
    },
    {
        mgdata: 'https://static.astrovedaGuru.com/astro-images/agents/13.png',
        rnames: "Pandit Diwakar Tripathi",
        exp: '16 year',
        language: "English, Hindi",
        expertise: "Vedic, Vastu, Numerology",
        rates: "25/Min",
        callnow: "Chat Now"
    }
];