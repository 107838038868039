import React, { useState, useEffect } from "react";
import { Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackSharpIcon from '@mui/icons-material/ArrowBackSharp';
import ganesh from "../images/ganesh.webp";
import ganesh1 from "../images/ganesh-1.webp";
import ganesh2 from "../images/ganesh-2.webp";
import ganesh3 from "../images/ganesh-3.webp";
import hanesh4 from "../images/ganesh-4.webp";
import axios from "axios";
const PujaSeva = () => {
    const navigate = useNavigate();
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [message, setMessage] = useState('');
    const [phoneError, setPhoneError] = useState("");

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        name: '',
        contactNo: '',
        gender: '',
        dob: '',
        tob: '',
        emailAddress: '',
        language: '',
        maritalStatus: '',
        birthPlace: ''
    });

    useEffect(() => {
        const storedData = {
            firstName: localStorage.getItem('firstName') || '',
            lastName: localStorage.getItem('lastName') || '',
            name: localStorage.getItem('userName') || '',
            contactNo: localStorage.getItem('contactNo') || '',
            gender: localStorage.getItem('gender') || '',
            dob: localStorage.getItem('birthDate') || '',
            tob: localStorage.getItem('birthTime') || '',
            emailAddress: localStorage.getItem('email') || '',
            language: localStorage.getItem('language') || '',
            maritalStatus: localStorage.getItem('maritalStatus') || '',
            birthPlace: localStorage.getItem('birthPlace') || ''
        };
        setFormData(storedData);
        setName(storedData.name);
        setEmail(storedData.emailAddress);
        setPhone(storedData.contactNo);
    }, []); 



    const handleConsultation = () => {
        const data = {
            name: name,
            email: email,
            phone: phone,
            message: message
        };

        axios.post('https://app.astrovedaguru.com/api/sendMail', data)
            .then(response => {
                console.log(response.data);
                alert("Consultation request submitted successfully!");
                setName("");
                setEmail("");
                setPhone("");
                setMessage("");
            })
            .catch(error => {
                console.error('Error submitting consultation:', error);
                alert("Failed to submit consultation request. Please try again later.");
            });
    };

    const handlePhoneChange = (e) => {
        const re = /^[0-9\b]+$/;
        const phoneNumber = e.target.value;

        if (phoneNumber.length === 0 || re.test(phoneNumber)) {
            setPhone(phoneNumber);
            if (phoneNumber.length !== 10) {
                setPhoneError("Please enter a valid 10-digit phone number.");
            } else {
                setPhoneError("");
            }
        }
    };
    return (
        <>
            <div className="main-contact">
                <div className='acharya-ajay'>
                    <Typography variant='h6' fontWeight="700" sx={{ alignitems: "center", padding: "10px" }}><ArrowBackSharpIcon fontWeight="bold" sx={{ fontSize: "20px", marginRight: "10px", alignItems: "center" }} onClick={() => { navigate("/") }} />Contact Us</Typography>
                </div>
                <div className="container mt-4">
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-1">
                            <div className="card puja-sevash">
                                <img src={ganesh} />
                            </div>
                            <Typography variant="h6" fontWeight="700" sx={{fontSize: "18px", marginTop:"20px"}}>Contact Information</Typography>
                            <br />
                            <Typography variant='subtitle1' fontWeight="600">117, Panditon Wali Gali, Mission Road, Near Shiv Mandir, Pathankot, Punjab 145001</Typography>
                            <br />
                            <Typography variant="h6" fontWeight="700" sx={{fontSize: "18px" }}>Support</Typography>
                            <br />
                            <Typography variant='p' fontWeight="600">Email: &nbsp; info@astrovedaguru.com</Typography>
                            <br/>
                            <Typography variant='p' fontWeight="600">Mobile: &nbsp;9417545844, &nbsp; 9115596303, &nbsp;7986722874</Typography>

                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-1 mt-1">
                            <div className="card puja-sevash-1">
                                <Typography variant="h2" fontWeight="500" marginLeft="20px" sx={{ fontSize: "24px", marginTop: "20px" }}>Send us a message</Typography>
                                <Typography variant="p" marginLeft="20px" sx={{ marginTop: "30px" }}>Enter Valid Name</Typography>
                                <input value={name}
                                    onChange={(e) => setName(e.target.value)} className="input-1" type="text" placeholder="Enter your Name" required></input>
                                <Typography variant="p" marginLeft="20px" sx={{ marginTop: "12px" }}>Email (Email is required)*</Typography>
                                <input value={email}
                                    onChange={(e) => setEmail(e.target.value)} className="input-1" type="email" placeholder="Enter your Email" required></input>
                                <Typography variant="p" marginLeft="20px" sx={{ marginTop: "12px" }}>Phone Number*</Typography>
                                <input value={phone}
                                    onChange={handlePhoneChange} className="input-1" type="tel" placeholder="Enter Phone Number" required></input>
                                {phoneError && (
                                    <Typography variant="p" marginLeft="20px" sx={{ color: "red" }}>
                                        {phoneError}
                                    </Typography>
                                )}
                                <textarea value={message}
                                    onChange={(e) => setMessage(e.target.value)} name="message" placeholder="Enter your message" rows="3" className='input-1' required></textarea>
                                <button disabled={phoneError || phone.length !== 10}
                                    onClick={handleConsultation} className="consultation" sx={{ alignitems: "center", cursor: "pointer", }} >Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PujaSeva;



// import React, { useState } from 'react';
// import { useNavigate } from "react-router-dom";
// import { Typography } from "@mui/material";
// import ArrowBackSharpIcon from '@mui/icons-material/ArrowBackSharp';
// import "./Style.css";
// const ContactDetail = () => {

//     const navigate = useNavigate();
//     const [input, setInput] = useState({
//         name: "",
//         email: "",
//         mobile: ""
//     });

//     const handleChange = (e) => {
//         setInput((prevState) => ({
//             ...prevState,
//             [e.target.name]: e.target.value
//         }));
//     };

//     const handleSubmit = (e) => {
//         e.preventDefault();
//         console.log("form submit", input);
//         setInput({
//             name: "",
//             email: "",
//             mobile: "",
//             message: ""
//         });
//     }
//     return (
//         <>
//             <div className='main-contact'>
//                 <div className="acharya-ajay">
//                     <Typography
//                         variant="h6"
//                         fontWeight="700"
//                         sx={{ display: "flex", alignitems: "center", padding: "20px" }}
//                     >
//                         <ArrowBackSharpIcon
//                             fontWeight="bold"
//                             sx={{
//                                 fontSize: "25px",
//                                 marginRight: "10px",
//                                 alignitems: "center",
//                             }}
//                             onClick={() => {
//                                 navigate("/");
//                             }}
//                         />
//                         Contact Us
//                     </Typography>
//                 </div>
//                 <br></br>
//                 <Typography variant="subtitle1" fontWeight="600" sx={{ marginLeft: "65px" }}>We love feedback- and we're always happy to help!</Typography>
//                 <br></br>
//                 <br></br>
//                 <Typography variant="h6" fontWeight="700" sx={{ marginLeft: "65px", fontSize: "18px" }}>Send us a message</Typography>
//                 <br></br>
//                 <Typography variant='subtitle2' fontWeight="600" sx={{ marginLeft: "65px" }}>Send us a message and we'll respond within 24 hours</Typography>
//                 <div className='form-detail' >
//                     <form className='row' onSubmit={handleSubmit}>
//                         <div className='col-lg-12 col-md-12 col-ms-12 mb-2'>
//                             <div className='row'>
//                                 <div className='col-4'>
//                                     <input onChange={handleChange} style={{minWidth:"268px"}} value={input.name} type='name' placeholder='Enter Name' className='form-control' name="name" />
//                                 </div>
//                             </div>
//                         </div>
//                         <div className='col-12 mb-2'>
//                             <div className='row'>
//                                 <div className='col-4'>
//                                     <input onChange={handleChange} style={{minWidth:"268px"}} value={input.email} type='email' placeholder='Enter Email Id' className='form-control' name="email" />
//                                 </div>
//                             </div>
//                         </div>
//                         <div className='col-12 mb-2'>
//                             <div className='row'>
//                                 <div className='col-4'>
//                                     <input onChange={handleChange} style={{minWidth:"268px"}} value={input.mobile} type='mobile' placeholder='Enter Contact No' className='form-control' name="mobile" />
//                                 </div>
//                             </div>
//                         </div>
//                         <div className='col-12 mb-2'>
//                             <textarea placeholder="Enter your message" rows="3" cols="35" onChange={handleChange} value={input.message} name="message"></textarea>
//                         </div>
//                         <div className='col-12 mb-2 '>
//                             <button className='btn btn-primary text-white'>Submit</button>
//                         </div>
//                     </form>
//                 </div>
//                 <Typography variant="h6" fontWeight="700" sx={{ marginLeft: "65px", fontSize: "18px" }}>Contact Information</Typography>
//                 <br></br>
//                 <Typography variant='subtitle1' fontWeight="600" sx={{ marginLeft: "65px" }}> 117, Panditon Wali Gali, Mission Road, Near Shiv Mandir,
//                     Pathankot, Punjab 145001</Typography>
//                 <br>
//                 </br>
//                 <br></br>
//                 <Typography variant="h6" fontWeight="700" sx={{ marginLeft: "65px", fontSize: "18px" }}>Support</Typography>
//                 <br></br>
//                 <Typography variant='p' fontWeight="600" sx={{ marginLeft: "65px" }}>ashwaniprashar6@gmail.com</Typography>
//             </div>

//         </>
//     );
// };

// export default ContactDetail;

// import React, { useState } from 'react';
// import { useNavigate } from "react-router-dom";
// import { Typography } from "@mui/material";
// import ArrowBackSharpIcon from '@mui/icons-material/ArrowBackSharp';
// import "./Style.css";
// import contactImage from "../images/contact.png"
// import Swal from 'sweetalert2';

// const ContactDetail = () => {
//     const navigate = useNavigate();

//     const [input, setInput] = useState({
//         name: "",
//         email: "",
//         mobile: "",
//         message: ""
//     });

//     const [mobileError, setMobileError] = useState("");

//     const handleChange = (e) => {
//         const { name, value } = e.target;

//         if (name === "mobile") {
//             // Validate mobile number length
//             if (value.length <= 10) {
//                 setInput(prevState => ({
//                     ...prevState,
//                     [name]: value
//                 }));
//                 setMobileError("");
//             } else {
//                 setMobileError("Mobile number should be maximum 10 digits.");
//             }
//         } else {
//             setInput(prevState => ({
//                 ...prevState,
//                 [name]: value
//             }));
//         }
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         console.log("form submit", input);

//         try {
//             const response = await fetch('https://app.astrovedaguru.com/api/sendMail', {
//                 method: 'POST',
//                 headers: {
//                     'Content-Type': 'application/json',
//                 },
//                 body: JSON.stringify({
//                     name: input.name,
//                     email: input.email,
//                     contact_no: input.mobile,
//                     message: input.message
//                 })
//             });

//             if (response.ok) {
//                 console.log('Message sent successfully');

//                 setInput({
//                     name: "",
//                     email: "",
//                     mobile: "",
//                     message: ""
//                 });
//                 Swal.fire({
//                     icon: 'success',
//                     title: 'Message Sending successfully!',
//                     showConfirmButton: false,
//                     timer: 1500
//                 });
//             } else {
//                 console.error('Failed to send message');
//                 Swal.fire({
//                     icon: 'error',
//                     title: 'Failed to Send Message',
//                     text: 'Please try again later.',
//                     confirmButtonText: 'OK'
//                 });
//             }
//         } catch (error) {
//             console.error('Error sending message:', error);
//         }
//     };

//     return (
//         <>
//             <div className='main-contact'>
//                 <div className="acharya-ajay">
//                     <Typography
//                         variant="h6"
//                         fontWeight="700"
//                         sx={{ display: "flex", alignItems: "center", padding: "10px"}}
//                     >
//                         <ArrowBackSharpIcon
//                             fontWeight="bold"
//                             sx={{
//                                 fontSize: "20px",
//                                 marginRight: "10px",
//                                 alignItems: "center",
//                                 cursor: "pointer"
//                             }}
//                             onClick={() => {
//                                 navigate("/");
//                             }}
//                         />
//                         Contact Us
//                     </Typography>
//                 </div>
//                 <br />

//                 <div className='contact-banner' style={{ width: "100%", height: "auto"}}>
//                     <img style={{width: "100%", height:"auto"}} src={contactImage} alt="loading" />
//                 </div>
//                 <Typography variant="subtitle1" fontWeight="600" sx={{ marginLeft: "65px", marginTop:"10px" }}>We love feedback and we're always happy to help!</Typography>
//                 <br />
//                 <br />
//                 <Typography variant="h6" fontWeight="700" sx={{ marginLeft: "65px", fontSize: "18px" }}>Send us a message</Typography>
//                 <br />
//                 <Typography variant='subtitle2' fontWeight="600" sx={{ marginLeft: "65px" }}>Send us a message and we'll respond within 24 hours</Typography>
//                 <div className='form-detail'>
//                     <form className='row' onSubmit={handleSubmit}>
//                         <div className='col-lg-12 col-md-12 col-ms-12 mb-2'>
//                             <div className='row'>
//                                 <div className='col-4'>
//                                     <input onChange={handleChange} style={{ minWidth: "268px" }} value={input.name} type='text' placeholder='Enter Name' className='form-control' name="name" />
//                                 </div>
//                             </div>
//                         </div>
//                         <div className='col-12 mb-2'>
//                             <div className='row'>
//                                 <div className='col-4'>
//                                     <input onChange={handleChange} style={{ minWidth: "268px" }} value={input.email} type='email' placeholder='Enter Email Id' className='form-control' name="email" />
//                                 </div>
//                             </div>
//                         </div>
//                         <div className='col-12 mb-2'>
//                             <div className='row'>
//                                 <div className='col-4'>
//                                     <input onChange={handleChange} style={{ minWidth: "268px" }} value={input.mobile} type='text' placeholder='Enter Contact No' className='form-control' name="mobile" />
//                                 </div>
//                                 {mobileError && (
//                                     <Typography
//                                         variant="p"
//                                         sx={{ color: "red", marginLeft: "10px", marginTop: "5px" }}
//                                     >
//                                         {mobileError}
//                                     </Typography>
//                                 )}
//                             </div>
//                         </div>
//                         <div className='col-12 mb-2'>
//                             <textarea onChange={handleChange} value={input.message} name="message" placeholder="Enter your message" rows="3" className='form-control'></textarea>
//                         </div>
//                         <div className='col-12 mb-2'>
//                             <button type="submit" className='btn btn-primary text-white'>Submit</button>
//                         </div>
//                     </form>
//                 </div>
//                 <Typography variant="h6" fontWeight="700" sx={{ marginLeft: "65px", fontSize: "18px" }}>Contact Information</Typography>
//                 <br />
//                 <Typography variant='subtitle1' fontWeight="600" sx={{ marginLeft: "65px" }}>117, Panditon Wali Gali, Mission Road, Near Shiv Mandir, Pathankot, Punjab 145001</Typography>
//                 <br />
//                 <br />
//                 <Typography variant="h6" fontWeight="700" sx={{ marginLeft: "65px", fontSize: "18px" }}>Support</Typography>
//                 <br />
//                 <Typography variant='p' fontWeight="600" sx={{ marginLeft: "65px" }}>info@astrovedaguru.com</Typography>
//             </div>
//         </>
//     );
// };

// export default ContactDetail;



