// import { configureStore } from '@reduxjs/toolkit'
// import tableSlice from './slice/table'
// import dataSlice from './slice/data'
// export const store = configureStore({
//   reducer: {
        
//         data:dataSlice,
//         table:tableSlice,
//   },
// });

import { createStore } from 'redux';
import rootReducer from '.././Redux/reducers'; 

const store = createStore(rootReducer);

export default store;       