import React from 'react'
import { useNavigate } from "react-router-dom";

const Footer = () => {
    const navigate = useNavigate();
    return (
        <>
            <div className='container mt-5'>
                <hr style={{ backgroundColor: "gray", height: "1px", color: "gray", width: "100%" }} />
                <div className='row' style={{ marginLeft: "40px" }}>
                    <div className='col-xl-4 col-lg-4 cl-md-6 col-sm-12 mb-1'>
                        <div className='card-card linkhover'>
                            <h6 className='about' style={{ fontWeight: "700" }}>ABOUT</h6>
                            <a style={{
                                cursor: "pointer", fontWeight: 600, color: "gray",
                                transition: 'ease-in'
                            }} onClick={() => { navigate("/ContactDetail") }}>Contact Us</a>
                            <br></br>
                            <a style={{ cursor: "pointer", fontWeight: 600, color: "gray" }} onClick={() => { navigate("/PrivacyPolicy") }}>Privacy policy</a>
                            <br></br>
                            <a style={{ cursor: "pointer", fontWeight: 600, color: "gray" }} onClick={() => { navigate("/TermsAndConditions") }}>Terms and Conditions</a>
                            <br></br>
                            <a style={{ cursor: "pointer", fontWeight: 600, color: "gray" }} onClick={() => { navigate("/Disclaimer") }}>Disclaimer</a>
                        </div>
                    </div>
                    <div className='col-xl-4 col-lg-4 cl-md-6 col-sm-12 mb-1'>
                        <div className='card-card linkhover' >
                            <h6 className='quick-links' style={{ fontWeight: "700" }}>QUICK LINKS</h6>
                            <a style={{ cursor: "pointer", fontWeight: 600, color: "gray" }} onClick={() => { navigate("/DailyRashiSearch") }}>Daily Horoscope</a>
                            <br></br>
                            <a href="https://www.abplive.com/astro" style={{ cursor: "pointer", fontWeight: 600, color: "gray" }} >Daily Feeds </a>
                            <br></br>
                            <a style={{ cursor: "pointer", fontWeight: 600, color: "gray" }} onClick={() => { navigate("/Reports") }}>Reports</a>
                        </div>
                    </div>
                    <div className='col-xl-4 col-lg-4 cl-md-6 col-sm-12 mb-1'>
                        <div className='card-card linkhover'>
                            <h6 className='follow-us' style={{ fontWeight: "700" }}>FOLLOW US</h6>
                            <a href="https://www.youtube.com/" style={{ cursor: "pointer", color: "gray" }}><i className="fa fa-youtube-play" style={{ fontSize: "25px", padding: "10px" }}></i></a>
                            <a href="https://www.facebook.com/" style={{ cursor: "pointer", fontWeight: 600, color: "gray" }}><i className="fa fa-facebook" style={{ fontSize: "25px", padding: "10px" }}></i></a>
                            <a href="https://www.instagram.com/" style={{ cursor: "pointer", fontWeight: 600, color: "gray" }}><i className="fa fa-instagram" style={{ fontSize: "25px", padding: "10px" }}></i></a>
                            <a href="https://www.x.com/" style={{ cursor: "pointer", fontWeight: 600, color: "gray" }}><i className="fa fa-twitter" style={{ fontSize: "25px", padding: "10px" }}></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Footer;