import React from 'react'
import { useNavigate } from "react-router-dom";
import ArrowBackSharpIcon from '@mui/icons-material/ArrowBackSharp';
import { Typography } from '@mui/material';
const DailyPanchang = () => {
    const navigate = useNavigate();
    return (
        <>
            <div className='main-panchang'>
                <div className='acharya-ajay'>
                    <Typography variant='h6' fontWeight="700" sx={{ alignitems: "center", padding: "10px" }}><ArrowBackSharpIcon fontWeight="bold" sx={{ fontSize: "20px", marginRight: "10px", alignitems: "center" }} onClick={() => { navigate("/") }} />Daily Panchang</Typography>
                </div>
                <div className='panchang-container mt-4'>
                    <div className='panchang-1'>
                        <div className='row'>
                            <div className='col-xl-3 col-lg-3 col-md-4 col-sm-5 col-12 mb-1'>
                                <p>Date :</p> </div>
                            <div className='col-xl-9 col-lg-9 col-md-8 col-sm-7 col-12 mb-1'>
                                <input type="date" className='form-control'></input>
                            </div>
                        </div>
                        <div className='row mt-2'>
                            <div className='col-xl-3 col-lg-3 col-md-4 col-sm-5 col-12 mb-1'>
                                <p>Location :</p>
                            </div>
                            <div className='col-xl-9 col-lg-9 col-md-8 col-sm-7 col-12 mb-1'>
                                <input type="location" className='form-control'></input>
                            </div>
                        </div>
                    </div>
                    <div className="panchang-2">
                        <br></br>
                        <Typography variant='h5' sx={{ fontWeight: "bold", fontSize: "20px" }}>Tithi</Typography>
                        <br></br>
                        <Typography variant='p' sx={{ fontSize: "17px", }}>Tithi Number</Typography>
                        <br></br>
                        <Typography variant='p' sx={{ fontSize: "17px", }}>Tithi Name</Typography>
                        <br></br>
                        <Typography variant='p' sx={{ fontSize: "17px", }}>Special</Typography>
                        <br></br>
                        <Typography variant='p' sx={{ fontSize: "17px", }}>Summary</Typography>
                        <br></br>
                        <Typography variant='p' sx={{ fontSize: "17px", }}>Deity</Typography>
                        <br></br>
                        <Typography variant='p' sx={{ fontSize: "17px", }}>End Time</Typography>
                        <br></br>
                        <br></br>
                        <Typography variant='h5' sx={{ fontWeight: "bold", fontSize: "20px" }}>Nakshatra</Typography>
                        <br></br>
                        <Typography variant='p' sx={{ fontSize: "17px", }}>Nakshatra Number</Typography>
                        <br></br>
                        <Typography variant='p' sx={{ fontSize: "17px", }}>Nakshatra Name</Typography>
                        <br></br> <Typography variant='p' sx={{ fontSize: "17px", }}>Special</Typography>
                        <br></br>
                        <Typography variant='p' sx={{ fontSize: "17px", }}>Summmary</Typography>
                        <br></br>
                        <Typography variant='p' sx={{ fontSize: "17px", }}>Deity</Typography>
                        <br></br>
                        <Typography variant='p' sx={{ fontSize: "17px", }}>End Time</Typography>
                        <br></br>
                        <br></br>
                        <Typography variant='h5' sx={{ fontWeight: "bold", fontSize: "20px" }}>Yog</Typography>
                        <br></br>
                        <Typography variant='p' sx={{ fontSize: "17px", }}>Yog Number</Typography>
                        <br></br>
                        <Typography variant='p' sx={{ fontSize: "17px", }}>Yog Name</Typography>
                        <br></br>
                        <Typography variant='p' sx={{ fontSize: "17px", }}>Special</Typography>
                        <br></br> <Typography variant='p' sx={{ fontSize: "17px", }}>Meaning</Typography>
                        <br></br>
                        <Typography variant='p' sx={{ fontSize: "17px", }}>End Time</Typography>
                        <br></br>
                        <br></br>
                        <Typography variant='h5' sx={{ fontWeight: "bold", fontSize: "20px" }}>Karan</Typography>
                        <br></br>
                        <Typography variant='p' sx={{ fontSize: "17px", }}>Karan Number</Typography>
                        <br></br>
                        <Typography variant='p' sx={{ fontSize: "17px", }}>Karan Name</Typography>
                        <br></br>
                        <Typography variant='p' sx={{ fontSize: "17px", }}>Special</Typography>
                        <br></br>
                        <Typography variant='p' sx={{ fontSize: "17px", }}>Deity</Typography>
                        <br></br>
                        <Typography variant='p' sx={{ fontSize: "17px", }}>End Time</Typography>
                        <br></br>
                        <br></br>
                        <Typography variant='h5' sx={{ fontWeight: "bold", fontSize: "20px" }}>Hindu Mah</Typography>
                        <br></br>
                        <Typography variant='p' sx={{ fontSize: "17px", }}>Purnimanta</Typography>
                        <br></br>
                        <Typography variant='p' sx={{ fontSize: "17px", }}>Amanta</Typography>
                        <br></br>
                        <Typography variant='p' sx={{ fontSize: "17px", }}>Paksha</Typography>
                        <br></br>
                        <Typography variant='p' sx={{ fontSize: "17px", }}>Ritu</Typography>
                        <br></br>
                        <Typography variant='p' sx={{ fontSize: "17px", }}>Sun Sign</Typography>
                        <br></br>
                        <Typography variant='p' sx={{ fontSize: "17px", }}>Moon Sign</Typography>
                        <br></br>
                        <Typography variant='p' sx={{ fontSize: "17px", }}>Ayana</Typography>
                        <br></br>
                        <Typography variant='p' sx={{ fontSize: "17px", }}>Panchang Yog</Typography>
                        <br></br>
                        <Typography variant='p' sx={{ fontSize: "17px", }}>Vikram Samavat</Typography>
                        <br></br>
                        <Typography variant='p' sx={{ fontSize: "17px", }}>Shaka Samavat</Typography>
                        <br></br>
                        <Typography variant='p' sx={{ fontSize: "17px", }}>Shaka Samavat Name</Typography>
                        <br></br>
                        <Typography variant='p' sx={{ fontSize: "17px", }}>Disha Sool</Typography>
                        <br></br>
                        <Typography variant='p' sx={{ fontSize: "17px", }}>Disha Sool Remedies</Typography>
                        <br></br>
                        <Typography variant='p' sx={{ fontSize: "17px", }}>Nak Sool</Typography>
                        <br></br>
                        <Typography variant='p' sx={{ fontSize: "17px", }}>Moon Nivas</Typography>
                        <br></br>
                        <br></br>
                        <Typography variant='h5' sx={{ fontWeight: "bold", fontSize: "20px" }}>Abhijit Muhurta</Typography>
                        <br></br>
                        <Typography variant='p' sx={{ fontSize: "17px", }}>Start:</Typography>
                        <br></br>
                        <Typography variant='p' sx={{ fontSize: "17px", }}>End:</Typography>
                        <br></br>
                        <br></br>
                        <Typography variant='h5' sx={{ fontWeight: "bold", fontSize: "20px" }}>Rahukaal</Typography>
                        <br></br>
                        <Typography variant='p' sx={{ fontSize: "17px" }}>Start:</Typography>
                        <br></br>
                        <Typography variant='p' sx={{ fontSize: "17px" }}>End:</Typography>
                        <br></br> <br></br>
                        <Typography variant='h5' sx={{ fontWeight: "bold", fontSize: "20px" }}>Gulikaal</Typography>
                        <br></br>
                        <Typography variant='p' sx={{ fontSize: "17px", }}>Start:</Typography>
                        <br></br>
                        <Typography variant='p' sx={{ fontSize: "17px", }}>End:</Typography>
                        <br></br>
                        <br></br>
                        <Typography variant='h5' sx={{ fontWeight: "bold", fontSize: "20px" }}>Yamghant Kaal</Typography>
                        <br></br>
                        <Typography variant='p' sx={{ fontSize: "17px", }}>Start:</Typography>
                        <br></br>
                        <Typography variant='p' sx={{ fontSize: "17px", }}>End:</Typography>
                    </div>
                </div>
            </div>
        </>
    )
}
export default DailyPanchang;