import React from 'react';
import { useNavigate } from 'react-router-dom';
import ArrowBackSharpIcon from '@mui/icons-material/ArrowBackSharp';
import { Typography } from '@mui/material';
const Disclaimer = () => {
    const navigate = useNavigate();
    return (
        <>
            <div className='main-disclaimer'>
            <div className="acharya-ajay">
                    <Typography
                        variant="h6"
                        fontWeight="700"
                        sx={{ alignitems: "center", padding: "10px" }}
                    >
                        <ArrowBackSharpIcon
                            fontWeight="bold"
                            sx={{
                                fontSize: "20px",
                                marginRight: "10px",
                                alignitems: "center",
                            }}
                            onClick={() => {
                                navigate("/");
                            }}
                        />
                        Disclaimer
                    </Typography>
                </div>
                <div className='disclaimer-container'>
                    <div className='disclaimer-heading'>
                        <Typography variant='h6' fontWeight="700">TERMS AND CONDITIONS</Typography>
                    </div>
                     <div className='terms'>
                        <Typography variant='p' fontWeight={"500"} sx={{ fontSize: "14px" }}>The information and data contained on Astro Veda Guru website / application ( “Platforms”) is to be treated purely for infotainment purposes only. Any prediction or other message that you receive is not a substitute for advice, programs, or treatment that you would normally receive from a licensed professional such as a lawyer, doctor, psychiatrist, or financial advisor.</Typography>
                        <br></br>
                        <br></br>
                        <Typography variant='p' fontWeight={"500"} sx={{ fontSize: "14px" }}>The Platforms, services and other materials as provided by info@astrovedaguru.com are on an “as is” basis without warranty of any kind, express, implied, statutory or otherwise, including the implied warranties of title, non -infringement, merchantability or fitness for a particular purpose. Without limiting the foregoing, makes no warranty that (i) the Platforms or the services will meet your requirements or your use of the Platforms or the services will be uninterrupted, timely, secure or error -free; (ii) the results that may be obtained from the use of the Platforms, services or materials will be effective, accurate or reliable; (iii) the quality of the Platforms, services or other materials will meet your expectations; or that (iv) any errors or defects in the Platforms, services or other materials will be corrected.</Typography>
                    </div> 
                </div>
            </div>
        </>
    );
};

export default Disclaimer;