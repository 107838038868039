// import React, { useState, useEffect } from 'react';
// import { dailysRashi } from "./DailyRashi";
// import { Typography } from '@mui/material';
// import {useNavigate} from "react-router-dom"
// import DailyRashiFile from "./DailyRashiFile";
// import axios from "axios"
// import ArrowBackSharpIcon from '@mui/icons-material/ArrowBackSharp';
// const DailyRashiSearch = () => {
//   const navigate = useNavigate();
//   const [dailRashiData, setDailRashiData] = useState(dailysRashi);
//   const [data2, setData2] = useState([]);
//   const months = ["Mar 21 - Apr 20", "Apr 21 - May 20", "May 21 - Jun 20", ]
//   useEffect(() => {
//     const fetchHoroscopeSigns = async () => {
//       try {
//         const response = await axios.post('https://app.astrovedaguru.com/api/getHororscopeSign');
//         const filteredData = response.data.recordList.filter(item => item.id >= 1 && item.id <= 13).sort((a, b) => a.id - b.id);
//         setData2(filteredData);
//       } catch (error) {
//         console.error('Error fetching data:', error);
//       }
//     };
//     fetchHoroscopeSigns();
//   }, []);

//   const rashiDetailsClick1 = (product)  => {
//     navigate(`/daily-horoscope`, { state: { rashiDetails: product, horoscopeSignId: product.id } });
//   };


//   return (
//     <>
//       <div className='main-contact'>
//         <div className='acharya-ajay'>
//           <Typography variant='h6' fontWeight="700" sx={{ alignitems: "center", padding: "10px" }}><ArrowBackSharpIcon fontWeight="bold" sx={{ fontSize: "20px", marginRight: "10px", alignitems: "center" }} onClick={() => { navigate("/") }} />Today's Horoscope</Typography>
//         </div>
//       </div>
//       <div className="daily">
//         <section className="item-section mt-4 container">
//         <div className='row mt-4 ' style={{ backgroundColor: "transparent" }}>
//         {data2.map((element, index) => (
//           <div className="card card11 col-4" key={index} onClick={() => rashiDetailsClick1(element)}>
//             <div className='card-header'>
//               <img src={`https://app.astrovedaguru.com/${element.image}`} className="card-img-top rashi" alt="..." />
//             </div>
//             <div className="card-body">
//               <h5 className="card-title nam">{element.name}</h5>
//               <h5 className="card-title months">{element.months}</h5>
//             </div>
//           </div>
//         ))}
//       </div>
//         </section>
//       </div>
//     </>
//   );
// };

// export default DailyRashiSearch;
import React, { useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ArrowBackSharpIcon from '@mui/icons-material/ArrowBackSharp';

const DailyRashiSearch = () => {
  const navigate = useNavigate();
  const [data2, setData2] = useState([]);
  const months = ["Mar 21 - Apr 20", "Apr 21 - May 20", "May 21 - Jun 20", "Jun 21 - July 20", "July 21 - Aug 20","Aug 21 - Sep 20","Sep 21 - Oct 20","Oct 21 - Nov 20","Nov 21 - Dec 20","Dec 21 - Jan 20","Jan 21 - Feb 20","Feb 21 - Mar 20",];

  useEffect(() => {
    const fetchHoroscopeSigns = async () => {
      try {
        const response = await axios.post('https://app.astrovedaguru.com/api/getHororscopeSign');
        const filteredData = response.data.recordList.filter(item => item.id >= 1 && item.id <= 13)
            .sort((a, b) => a.id - b.id)
            .map((item, index) => ({
              ...item,
              monthIndex: index % months.length // This associates each item with a month based on its index
            }));
        setData2(filteredData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchHoroscopeSigns();
  }, []);

  const rashiDetailsClick1 = (product) => {
    navigate(`/daily-horoscope`, { state: { rashiDetails: product, horoscopeSignId: product.id } });
  };

  return (
    <>
      <div className='main-contact'>
        <div className='acharya-ajay'>
          <Typography variant='h6' fontWeight="700" sx={{ alignitems: "center", padding: "10px" }}>
            <ArrowBackSharpIcon fontWeight="bold" sx={{ fontSize: "20px", marginRight: "10px", alignitems: "center" }} onClick={() => { navigate("/") }} />
            Today's Horoscope
          </Typography>
        </div>
      </div>
      <div className="daily">
        <section className="item-section mt-4 container">
          <div className='row mt-4 ' style={{ backgroundColor: "transparent" }}>
            {data2.map((element, index) => (
              <div className="card card11 col-4" key={index} onClick={() => rashiDetailsClick1(element)}>
                <div className='card-header'>
                  <img src={`https://app.astrovedaguru.com/${element.image}`} className="card-img-top rashi" alt="..." />
                </div>
                <div className="card-body">
                  <h5 className="card-title nam">{element.name}</h5>
                  <h5 className="card-title months">{months[element.monthIndex]}</h5>
                </div>
              </div>
            ))}
          </div>
        </section>
      </div>
    </>
  );
};

export default DailyRashiSearch;
