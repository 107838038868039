import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useLocation} from "react-router-dom";
import { rashiList } from "./HoroscopeData";
import HoroscopeFile from "./HoroscopeFile";

const HoroscopeSearch = () => {
  const location = useLocation();
  const isHomePage = location.pathname === '/';
  const [data, setData] = useState([]);
  const [horoData, setHoroData] = useState(rashiList);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post('https://app.astrovedaguru.com/api/getAstromallProduct', {
          
        });

        setData(response.data.recordList.slice(0,6));

        
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
          
    fetchData();
  }, []); 


  const handleDetailsClick = (product) => {
    navigate(`/HoroscopeDetails`, { state: { astrologerDetails: product } });
    // const formattedName = product.name.toLowerCase().replace(/\s+/g, '-'); 
    // navigate(`/${formattedName}`, { state: {  astrologerDetails: product } });
  };

  return (    
    <div className="container horoscope-1 mt-3">
      {isHomePage && (
        <div className="horoscope">
          <div className="horoscope-content">
            <h5>Horoscope Reports</h5>
            <a className="seeall" style={{ cursor: "pointer", fontWeight: "700", color:" #d73878" }} onClick={() => { navigate('/Reports') }}>SEE ALL<i className="fa fa-chevron-right" style={{ fontSize: "14px", padding: "5px" }}></i></a>
          </div>
          <p>Get in-depth sights on your life</p>
          
          <section className="item-section mt-4 container">
            <div className="row">
              {data.map((element, index) => (
                <div className="card4 col-xl-2 col-lg-2 col-md-3 col-sm-6 col-12 mb-1" key={index}>
                  <img src={`https://app.astrovedaguru.com/${element.productImage}`} className="card-img-top imagedata" alt="..." />
                  <div className="card-body">
                    <h5 className="card-title listname">{element.name}</h5>
                    <p className="rat"><span style={{ color: "black", marginRight: "5px" }}>at</span>₹ {element.amount}</p>
                    
                    <div className='card4-btn'  onClick={() => handleDetailsClick(element)}>
                      <button type="button" className="btn"><i className="fa fa-file-o"></i></button>
                      <span className='span-2' style={{ cursor: "pointer" }}>Buy Now </span>
                    </div>
                  </div>
                  <div className="extra "></div>
                </div>
              ))}
            </div>
          </section>
        </div>
      )}
    </div>
  );
  
};

export default HoroscopeSearch;
