import "./Style.css";
import React, { useState } from "react";
import { astroCard } from "./AstroFile";
import Cards from "./Cards";
const AstroSearch = () => {
  const [cardsData, setCardsData] = useState(astroCard);
  return (
    <section className="item-section mt-4 container">
      <div className="scroll-container">
        <h4 className="scroll-text">Astro Veda Guru</h4>
      </div>
      <div className="row talkdata-1">
        <Cards data={cardsData} className="col-3 mb-2" />
      </div>
    </section>
  );
};

export default AstroSearch;
