import React, { useState } from 'react';
import { detailsData } from "./ChatAstroData1";
import ChatAstroFile1 from "./ChatAstroFile1";

import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";
import ArrowBackSharpIcon from '@mui/icons-material/ArrowBackSharp';

const ChatAstroSearch1 = () => {

  const navigate = useNavigate();

  const [detailData, setDetailData] = useState(detailsData);
  return (
    <>
      <div className='main-contact'>
        <div className='acharya-ajay'>
          <Typography variant='h6' fontWeight="700" sx={{ alignitems: "center", padding: "10px" }}><ArrowBackSharpIcon fontWeight="bold" sx={{ fontSize: "20px", marginRight: "10px", alignitems: "center" }} onClick={() => { navigate("/") }} />Chat with Astrologer</Typography>
        </div>
        <div className="details">
          <section className="item-section mt-4 container">
            <div className="row">
              <ChatAstroFile1 data={detailData} className="col-3 mb-2" />
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default ChatAstroSearch1;