export const astrologerData = [
    {
        mgdata: "../images/suman-1.webp",
        rnames: 'Acharya Suman Lata',
        exp: '14 year',
        language: "English, Hindi",
        expertise: "Vedic, Lal Kitab, Horary",
        rates: "25/Min",
        callnow: "Call Now"
    },
    {
        mgdata: '../images/surinderBala.webp',
        rnames: 'Acharya Surinder Bala',
        exp: '10 year',
        language: "English, Hindi",
        expertise: "Vedic",
        rates: "27/Min",
        callnow: "Call Now"
    },
    {
    mgdata: "../images/sushmaTanvar.webp",
    rnames: "Acharya Sushma Tanvar",
    exp: '10 year',
    language: "English, Hindi",
    expertise: "Vaidik",
    rates: "30/Min",
    callnow: "Call Now"
    },
    {
      mgdata: "../images/prashnKundali.webp",
      rnames: "Acharya Pavneet Sharma",
      exp: '15 year',
      language: "Hindi",
      expertise: "Numerologist",
      rates: "24/Min",
      callnow: "Call Now"
    },
    {
        mgdata: "../images/anupumaGupta.webp",
        rnames: "Acharya Anupama Gupta",
        exp: '27 year',
        language: "English, Hindi",
        expertise: "Vedic, Prashana",
        rates: "35/Min",
        callnow: "Call Now"
    },
    {
        mgdata: 'https://static.astrovedaGuru.com/astro-images/agents/37.png',
        rnames: "Acharya ArvidS",
        exp: '10 year',
        language: "English, Hindi",
        expertise: "Vedic, Vastu, KP",
        rates: "33/Min",
        callnow: "Call Now"
    },
    {
        mgdata: 'https://static.astrovedaGuru.com/astro-images/agents/shipra-124.jpg',
        rnames: "Acharya Shipra",
        exp: '10 year',
        language: "English, Hindi",
        expertise: "Vedic, Numerology",
        rates: "36/Min",
        callnow: "Call Now"
    },
    {
        mgdata: 'https://static.astrovedaGuru.com/astro-images/agents/c46.jpg',
        rnames: "Acharya PankajK",
        exp: '10 year',
        language: "English, Hindi",
        expertise: "Vedic, Vastu, Palmistry",
        rates: "40/Min",
        callnow: "Call Now"
    },
    {
        mgdata: 'https://static.astrovedaGuru.com/astro-images/agents/10.png',
        rnames: "Acharya Ajay",
        exp: '21 year',
        language: "English, Hindi",
        expertise: "Vedic",
        rates: "42/Min",
        callnow: "Call Now"
    },
    {
        mgdata: 'https://static.astrovedaGuru.com/astro-images/agents/shivpraksash%20124.jpg',
        rnames: "Acharya ShivP",
        exp: '10 year',
        language: "English, Hindi",
        expertise: "Vedic",
        rates: "45/Min",
        callnow: "Call Now"
    },
    {
        mgdata: 'https://static.astrovedaGuru.com/astro-images/agents/c41.jpg',
        rnames: "Acharya Subramanaya",
        exp: '12 year',
        language: "English, Hindi",
        expertise: "Vedic",
        rates: "50/Min",
        callnow: "Call Now"
    },
    {
        mgdata: 'https://static.astrovedaGuru.com/astro-images/agents/c44.jpg',
        rnames: "Tarot Rashmi",
        exp: '10 year',
        language: "English, Hindi",
        expertise: "Vedic, Numerology",
        rates: "37/Min",
        callnow: "Call Now"
    },
    {
        mgdata: 'https://static.astrovedaGuru.com/astro-images/agents/13.png',
        rnames: "Pandit Diwakar Tripathi",
        exp: '16 year',
        language: "English, Hindi",
        expertise: "Vedic, Numerology",
        rates: "41/Min",
        callnow: "Call Now"
    }
    
];