import React, { useState, useEffect } from 'react';
import "./Style.css";
import { monthlyData } from "./MonthlyData";
import MonthlyFile from "./MonthlyFile";
// import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import { useNavigate, useLocation } from "react-router-dom";
import { Typography } from "@mui/material";
import ArrowBackSharpIcon from '@mui/icons-material/ArrowBackSharp';


const MonthlyFileSearch = ({ functionLogin }) => {

  const [products, setProducts] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const isHomePage = location.pathname === '/Reports';

  const [monthData, setMonthData] = useState(monthlyData);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.post('https://app.astrovedaguru.com/api/getAstromallProduct');
        console.log('API Response:', response.data);
        if (response.status === 200) {

          if (Array.isArray(response.data.recordList)) {
            setProducts(response.data.recordList);
          } else {
            console.error('API returned non-array data:', response.data);
          }
        } else {
          console.error('API request failed with status:', response.status);
        }
      } catch (error) {
        console.error('Error fetching data:', error);

      }
    };

    fetchProducts();
  }, []);

//   const token = localStorage.getItem("token");
//   const fn2 = () => {
//     if(!token) {
//       showHide();
//   } else {
//     handleLoginIn();
//     handleLoginInTo();   
//   }
// }
const handleCardClick = (product) => {
  
  navigate(`/HoroscopeDetails`, { state: { astrologerDetails: product } });
}


  return (
    <>
      <div className='main-contact'>
        <div className="acharya-ajay">
          {isHomePage &&
            <Typography
              variant="h6"
              fontWeight="700"
              sx={{ alignitems: "center", padding: "10px" }}
            >

              <ArrowBackSharpIcon
                fontWeight="bold"
                sx={{
                  fontSize: "20px",
                  marginRight: "10px",
                  alignitems: "center",
                }}
                onClick={() => {
                  navigate("/");
                }}
              />
              Horoscope Reports
            </Typography>
          }

          {/* <Typography
            variant="h6"
            fontWeight="700"
            sx={{ alignitems: "center", padding: "10px" }}
          >
            
            <ArrowBackSharpIcon
              fontWeight="bold"
              sx={{
                fontSize: "20px",
                marginRight: "10px",
                alignitems: "center",
              }}
              onClick={() => {
                navigate("/");
              }}
            />
            Horoscope Reports
          </Typography> */}
        </div>
        {isHomePage &&
          <>
            <div className="monthlyscope">
              <section className="item-section mt-4 container">
                <div className="row monthly">
                  {/* <MonthlyFile data={monthData} /> */}
                  {/* {!isHomePage && <MonthlyFile showHide={showHide} className="col-3 mb-2" />} */}
                  {products.map((product, index) => (
                    <div className="col-lg-6 col-md-6 col-sm-12 mb-3" key={index}>
                      <div className="card card9">
                        <div className="monthly">
                          <div className="card-body">
                            <div className="row">
                              <div className="col-5">
                                <img src={`https://app.astrovedaguru.com/${product.productImage}`} className="card-img-top m img-fluid" alt={product.name} />
                              </div>
                              <div className="col-7">
                                <h3>{product.name}</h3>
                                <p className="p">{product.features}</p>
                                <div className="card9-content">
                                  <p className="p1">
                                    <CurrencyRupeeIcon fontWeight="700" color="#d73878" sx={{ fontSize: "17px", marginLeft:"-5px", marginTop:"-2px" }} />
                                    {product.amount}
                                  </p>
                                  {/* onClick={functionLogin} */}
                                  <div className="both-btn"  style={{ cursor: "pointer" }} onClick={() => handleCardClick(product)}>
                                    <button type="button" className="btn-btn">
                                      <i className='fa fa-file-o' style={{ fontSize: "24px" }}></i>
                                    </button>
                                    <span className="span-2">Buy Now</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </section>
            </div>
          </>
        }
      </div>
    </>
  );
};
export default MonthlyFileSearch;