import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField } from '@mui/material';
import ArrowBackSharpIcon from '@mui/icons-material/ArrowBackSharp';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import axios from 'axios';

const OrderHistory = () => {
    const navigate = useNavigate();
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [filter, setFilter] = useState('');

    useEffect(() => {
        const fetchOrders = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.post('https://app.astrovedaguru.com/api/userOrder/get', '', {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });
                console.log(response.data.recordList); // Ensure that the response data is an array
                if (Array.isArray(response.data.recordList)) {
                    setOrders(response.data.recordList);
                } else {
                    throw new Error('Invalid response format');
                }
            } catch (err) {
                setError('Failed to fetch orders.');
                console.error(err);
            } finally {
                setLoading(false);
            }
        };

        fetchOrders();
    }, []);

    // Filtered orders based on the product name
    const filteredOrders = orders.filter(order =>
        order.productName.toLowerCase().includes(filter.toLowerCase())
    );

    return (
        <div className='main-contact'>
            <div className='acharya-ajay'>
                <Typography variant='h6' fontWeight="700" sx={{ alignItems: "center", padding: "10px" }}>
                    <ArrowBackSharpIcon 
                        fontWeight="bold" 
                        sx={{ fontSize: "20px", marginRight: "10px", alignItems: "center" }} 
                        onClick={() => { navigate("/") }} 
                    />
                    Order History
                </Typography>
            </div>
            <div className='container mt-3'>
                <div className="row">
                    <div className="col-12">
                        <div className="card order">
                            {/* Filter input */}
                            <TextField
                                label="Filter by Product Name"
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                value={filter}
                                onChange={(e) => setFilter(e.target.value)}
                            />
                            {loading ? (
                                <div className='loading'>
                                    <Typography variant='h6' sx={{ textAlign: 'center', marginTop: '20px' }}>
                                        Loading...
                                    </Typography>
                                </div>
                            ) : error ? (
                                <div className='error'>
                                    <Typography variant='h6' sx={{ textAlign: 'center', marginTop: '20px' }}>
                                        {error}
                                    </Typography>
                                </div>
                            ) : filteredOrders.length === 0 ? (
                                <div className='no-orders'>
                                    <Typography variant='h6' sx={{ textAlign: 'center', marginTop: '20px' }}>
                                        No orders found
                                    </Typography>
                                </div>
                            ) : (
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Order Number</TableCell>
                                                <TableCell>Product</TableCell>
                                                <TableCell>Amount</TableCell>
                                                <TableCell>Order Date</TableCell>
                                                <TableCell>Address</TableCell>
                                                <TableCell>Status</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {filteredOrders.map((order) => (
                                                <TableRow key={order.id}>
                                                    <TableCell>{order.id}</TableCell>
                                                    <TableCell>{order.productName}</TableCell>
                                                    <TableCell>
                                                        <CurrencyRupeeIcon sx={{ fontSize: "16px" }} /> {order.payableAmount}
                                                    </TableCell>
                                                    <TableCell>{new Date(order.created_at).toLocaleDateString()}</TableCell>
                                                    <TableCell>{order.flatNo + ", "+order.landmark+ ", "+order.city+ ", "+order.state + ", "+order.country + " - "+order.pincode }</TableCell>
                                                    <TableCell>{order.orderStatus}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OrderHistory;
