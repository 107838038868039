// import React, { useState } from 'react';
// import { useNavigate } from "react-router-dom";
// import { Typography } from '@mui/material';
// import ArrowBackSharpIcon from '@mui/icons-material/ArrowBackSharp';
// import VisibilityIcon from '@mui/icons-material/Visibility';
// import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
// import axios from "axios";

// const ResetPassword = () => {
//     const navigate = useNavigate();
//     const [otp, setOtp] = useState("");
//     const [newPassword, setNewPassword] = useState("");
//     const [confirmPassword, setConfirmPassword] = useState("");
//     const [error, setError] = useState(null);
//     const [error1, setError1] = useState(null);

//     const [showPassword1, setShowPassword1] = useState(false);
//     const [openSnackbar, setOpenSnackbar] = useState(false);

//     const togglePasswordVisibility1 = () => {
//         setShowPassword1(!showPassword1);
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();

//         setError(null);
//         setError1(null);

//         if (!otp) {
//             setError("OTP is required");
//             return;
//         }
//         if (!newPassword) {
//             setError("New Password is required");
//             return;
//         }
//         if (!confirmPassword) {
//             setError("Confirm Password is required");
//             return;
//         }

//         if (newPassword !== confirmPassword) {
//             setError1("Passwords do not match");
//             return;
//         }

//         const data = {
//             code: otp,
//             password: newPassword,
//             confirm_password: confirmPassword
//         };

//         try {
//             const response = await axios.post('https://app.astrovedaguru.com/api/reset-password', data);

//             if (response.status === 200) {
//                 setOpenSnackbar(true);
//                 setTimeout(() => navigate('/'), 2000);
//             } else {
//                 throw new Error('Failed to reset password');
//             }
//         } catch (error) {
//             setError(error.message);
//         }
//     };


//     return (
//         <>
//             <div className='main-panchang'>
//                 <div className='acharya-ajay'>
//                     <Typography variant='h6' fontWeight="700" sx={{ alignitems: "center", padding: "10px" }}><ArrowBackSharpIcon fontWeight="bold" sx={{ fontSize: "20px", marginRight: "10px", alignitems: "center" }} onClick={() => { navigate("/") }} />Reset Password</Typography>
//                 </div>
//                 <div className='privacy-container '>
//                     <div className='privacy-heading'>
//                         <Typography variant='h6' fontWeight="700">Reset Password</Typography>
//                     </div>
//                     <div className='container mt-3'>
//                         <div className='row col-xl-6 col-lg-6 col-md-8 col-sm-12 col-12'>
//                             <div className=' col-xl-6 col-lg-6 col-md-8 col-sm-12 col-12'>
//                                 <div className='reset-password'>
//                                     <div className='mb-3'>
//                                         <Typography variant="p" fontWeight="500" sx={{ fontSize: "20px" }}>Enter OTP</Typography>
//                                         <input value={otp} onChange={(e) => setOtp(e.target.value)} style={{ borderRadius: "8px" }} className="form-control" type="text" pattern="[0-9]*" inputMode="numeric" placeholder="Enter Otp" required />

//                                     </div>
//                                     <div className='mb-3'>
//                                         <Typography variant="p" fontWeight="500" sx={{ fontSize: "20px" }}>Enter New Password</Typography>
//                                         <input value={newPassword} onChange={(e) => setNewPassword(e.target.value)} style={{ borderRadius: "8px" }} className="forgot-new form-control" type={showPassword1 ? 'text' : 'password'} placeholder="Enter New Paasword" required />
//                                         {showPassword1 ? (
//                                             <VisibilityIcon
//                                                 onClick={togglePasswordVisibility1}
//                                                 style={{ cursor: "pointer", position: "absolute", right: "10px", top: "10px" }}
//                                             />
//                                         ) : (
//                                             <VisibilityOffIcon
//                                                 onClick={togglePasswordVisibility1}
//                                                 style={{ cursor: "pointer", position: "absolute", right: "10px", top: "10px" }}
//                                             />
//                                         )}
//                                     </div>
//                                     <div className='mb-3'>
//                                         <Typography variant="p" fontWeight="500" sx={{ fontSize: "20px" }}>Enter Confirm Password</Typography>
//                                         <input value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} style={{ borderRadius: "8px" }} className="forgot-confirm form-control" type="password" placeholder="Enter Confirm Password" required />

//                                     </div>
//                                 </div>
//                                 {error && <div className="alert alert-danger" role="alert">{error}</div>}
//                                 {error1 && <div className="alert alert-danger" role="alert">{error1}</div>}
//                                 <div className='col-12 mb-2 for-submit'>
//                                     <button onClick={handleSubmit} style={{ border: "none", borderRadius: "10px", justifyContent: "center", alignItems: "center", width: "100px", height: "35px", backgroundColor: "rgb(233, 97, 7)", color: "white", fontSize: "20px", fontWeight: "500" }} type="submit" className='reset-submit'>Submit</button>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </>
//     )
// }

// export default ResetPassword

import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Typography, TextField, IconButton, InputAdornment, Button, Snackbar } from '@mui/material';
import ArrowBackSharpIcon from '@mui/icons-material/ArrowBackSharp';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import axios from "axios";

const ResetPassword = () => {
    const navigate = useNavigate();
    const [otp, setOtp] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [error, setError] = useState(null);
    const [error1, setError1] = useState(null);
    const [showPassword1, setShowPassword1] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false); // State for confirm password visibility
    const [openSnackbar, setOpenSnackbar] = useState(false);

    const togglePasswordVisibility1 = () => {
        setShowPassword1(!showPassword1);
    };

    const togglePasswordVisibility2 = () => {
        setShowPassword2(!showPassword2);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        setError(null);
        setError1(null);

        if (!otp) {
            setError("OTP is required");
            return;
        }
        if (!newPassword) {
            setError("New Password is required");
            return;
        }
        if (!confirmPassword) {
            setError("Confirm Password is required");
            return;
        }

        if (newPassword !== confirmPassword) {
            setError1("Passwords do not match");
            return;
        }

        const data = {
            code: otp,
            password: newPassword,
            confirm_password: confirmPassword
        };

        try {
            const response = await axios.post('https://app.astrovedaguru.com/api/reset-password', data);

            if (response.status === 200) {
                setOpenSnackbar(true);
                setTimeout(() => navigate('/'), 2000);
            } else {
                throw new Error('Failed to reset password');
            }
        } catch (error) {
            setError(error.message);
        }
    };

    return (
        <div className='main-panchang'>
            <div className='acharya-ajay'>
                <Typography variant='h6' fontWeight="700" sx={{ display: "flex", alignItems: "center", padding: "10px" }}>
                    <ArrowBackSharpIcon sx={{ marginRight: "10px", cursor: "pointer" }} onClick={() => navigate("/")} />
                    Reset Password
                </Typography>
            </div>
            <div className='privacy-container'>
                <div className='privacy-heading'>
                    <Typography variant='h6' fontWeight="700">Reset Password</Typography>
                </div>
                <div className='container mt-3'>
                    <div className='row'>
                        <div className='col-xl-6 col-lg-6 col-md-8 col-sm-12 col-12'>
                            <div className='reset-password'>
                                <form onSubmit={handleSubmit}>
                                    <TextField
                                        value={otp}
                                        onChange={(e) => setOtp(e.target.value)}
                                        label="Enter OTP"
                                        variant="outlined"
                                        fullWidth
                                        required
                                        error={!!error}
                                        helperText={error}
                                        InputProps={{
                                            style: { borderRadius: "8px" }
                                        }}
                                        sx={{ marginBottom: "20px" }}
                                    />
                                    <TextField
                                        value={newPassword}
                                        onChange={(e) => setNewPassword(e.target.value)}
                                        label="Enter New Password"
                                        variant="outlined"
                                        fullWidth
                                        type={showPassword1 ? 'text' : 'password'}
                                        required
                                        error={!!error1}
                                        helperText={error1}
                                        InputProps={{
                                            style: { borderRadius: "8px" },
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={togglePasswordVisibility1}
                                                        edge="end"
                                                        sx={{ padding: 0, marginRight: "-10px" }}
                                                    >
                                                        {showPassword1 ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                        sx={{ marginBottom: "20px" }}
                                    />
                                    <TextField
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                        label="Enter Confirm Password"
                                        variant="outlined"
                                        fullWidth
                                        type={showPassword2 ? 'text' : 'password'} // Toggle visibility for confirm password
                                        required
                                        InputProps={{
                                            style: { borderRadius: "8px" },
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={togglePasswordVisibility2}
                                                        edge="end"
                                                        sx={{ padding: 0, marginRight: "-10px" }}
                                                    >
                                                        {showPassword2 ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                        sx={{ marginBottom: "20px" }}
                                    />
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        style={{ backgroundColor: "rgb(233, 97, 7)", color: "white", borderRadius: "10px" }}
                                        size="halfwidth"
                                    >
                                        Submit
                                    </Button>
                                </form>
                                <Snackbar
                                    open={openSnackbar}
                                    autoHideDuration={2000}
                                    onClose={() => setOpenSnackbar(false)}
                                    message="Password reset successful"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResetPassword;